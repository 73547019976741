var model = model || {};

model.Object = function () {
    var self = this;

    self.id = ko.observable().extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.objectNo = ko.observable().extend({ title: "", integer: true });
    self.name = ko.observable().extend({ title: "Objektsnamn", maxLength: 100 });
    self.address = ko.observable().extend({ title: "Adress", maxLength: 100 });
    self.buildingClassificationId = ko.observable().extend({ title: "", integer: true });
    self.objectTypeId = ko.observable().extend({ title: "", integer: true });
    self.estateId = ko.observable().extend({ title: "", integer: true });
    self.caseWorkerId = ko.observable().extend({ title: "", integer: true });
    self.meetingHall = ko.observable().extend({ title: "Samlingslokaler", bool: true });
    self.fireSafetyInfoExists = ko.observable().extend({ title: "Brandskyddsdokumentation", bool: true });
    self.sprinklerWater = ko.observable().extend({ title: "Vatten", bool: true });
    self.sprinklerCo2 = ko.observable().extend({ title: "CO2", bool: true });
    self.sprinklerHalon = ko.observable().extend({ title: "Halon", bool: true });
    self.sprinklerOther = ko.observable().extend({ title: "Annan", bool: true });
    self.sprinklerRequired = ko.observable().extend({ title: "Myndighetskrav", bool: true });
    self.semiProtection = ko.observable().extend({ title: "Delskydd", bool: true });
    self.fireVentilation = ko.observable().extend({ title: "Brandventilation", bool: true });
    self.fireVentilationRequired = ko.observable().extend({ title: "Myndighetskrav", bool: true });
    self.administrationNotes = ko.observable().extend({ title: "Förvaltningsanteckningar" });
    self.buildingNotes = ko.observable().extend({ title: "Byggnadsanteckningar", type: model.Memo });
    self.businessNotes = ko.observable().extend({ title: "Verksamhetsanteckningar", type: model.Memo });

    // Tillsyn
    self.hazardous = ko.observable().extend({ title: "Farlig verksamhet enligt LSO 2:4", bool: true });
    self.ownerReportsSafety = ko.observable().extend({ title: "Ägaren ska redogöra för brandskyddet", bool: true });
    self.culturalObject = ko.observable().extend({ title: "Kulturhistorisk värdefull byggnad", bool: true });
    self.inspectionGroupId = ko.observable().extend({ title: "", integer: true }); 
    self.inspectionArea = ko.observable().extend({ title: "Tillsynsområde", bool: true });
    self.lsoInspection = ko.observable().extend({ title: "", bool: true }); 
    self.lsoDeadline = ko.observable().extend({ title: "Frist", integer: true });
    self.lsoDebFactor = ko.observable().extend({ title: "Faktor", number: true }); 
    self.lsoAgreement = ko.observable().extend({ title: "Avtal", bool: true });
    self.lsoLatest = ko.observable().extend({ title: "Senaste" }); 
    self.lsoPlanned = ko.observable().extend({ title: "Planerad" });
    self.plannedLso = ko.observable().extend({ title: "Planerad" });
    self.lsoPlannedApproved = ko.observable().extend({ title: "Godkänd", bool: true });
    self.lbeInspection = ko.observable().extend({ title: "", bool: true }); 
    self.lbeDeadline = ko.observable().extend({ title: "Frist", integer: true });
    self.lbeDebFactor = ko.observable().extend({ title: "Faktor", number: true });
    self.lbeAgreement = ko.observable().extend({ title: "Avtal", bool: true });
    self.lbeLatest = ko.observable().extend({ title: "Senaste" });
    self.lbePlanned = ko.observable().extend({ title: "Planerad" });
    self.plannedLbe = ko.observable().extend({ title: "Planerad" });
    self.lbePlannedApproved = ko.observable().extend({ title: "Godkänd", bool: true });
    self.lsoLbeInspection = ko.observable().extend({ title: "", bool: true }); 
    self.lsoLbeDebFactor = ko.observable().extend({ title: "Faktor", number: true });
    self.lsoLbeAgreement = ko.observable().extend({ title: "Avtal", bool: true });
    self.lsoLbeLatest = ko.observable().extend({ title: "Senaste" });
    self.lsoLbePlanned = ko.observable().extend({ title: "Planerad" });
    self.plannedLsoLbe = ko.observable().extend({ title: "Planerad" });
    self.lsoLbePlannedApproved = ko.observable().extend({ title: "Godkänd", bool: true });
    self.lsoLbeDeadline = ko.observable().extend({ title: "Frist", integer: true });
    self.lsoTariffId = ko.observable().extend({ title: "", integer: true });
    self.lbeTariffId = ko.observable().extend({ title: "", integer: true }); 
    self.lsoLbeTariffId = ko.observable().extend({ title: "", integer: true }); 
    self.inspectionContactId = ko.observable().extend({ title: "", integer: true });
    self.inspectionNotes = ko.observable().extend({ title: "Anteckningar", type: model.Memo });
    // This subscribe fixes an issue with select setting value to undefined when initial value is null which causes tekis components to think the view is modfified
    self.inspectionContactId.subscribe(function (val) { if (val === undefined) self.inspectionContactId(null); });

    // Tillstånd
    self.permitExplosives = ko.observable().extend({ title: "Explosiv vara", bool: true });
    self.permitFireHazardous = ko.observable().extend({ title: "Brandfarlig vara", bool: true });
    self.permitPyrotech = ko.observable().extend({ title: "Pyroteknisk utrustning", bool: true });
    self.permitOxygen = ko.observable().extend({ title: "Syrgas", bool: true });
    self.permitOtherGas = ko.observable().extend({ title: "Övriga komprimerade gaser", bool: true });
	self.permitLbeNotes = ko.observable().extend({ title: "Anteckningar LBE", type: model.Memo });
	self.permitRiskNotes = ko.observable().extend({ title: "Särskilda risker", type: model.Memo });
    
    // Related data
    self.meetingHalls = ko.observableArray().extend({ title: "Samlingslokaler", type: model.MeetingHall });
    self.objectType = ko.observable().extend({ title: "Objektstyp" });
    self.inspectionGroups = ko.observableArray().extend({ title: "Tillsynsgrupper", type: model.InspectionGroup });
    self.alarms = ko.observableArray().extend({ title: "Automatlarm", type: model.Alarm });
    self.lsoTariff = ko.observable().extend({ type: model.Tariff });
    self.lbeTariff = ko.observable().extend({ type: model.Tariff });
    self.lsoLbeTariff = ko.observable().extend({ type: model.Tariff });
    self.inspectionContact = ko.observable().extend({ type: model.Contact });
    self.organisations = ko.observableArray().extend({ type: model.Organisation });
    self.emergencies = ko.observableArray().extend({ title: "Insatser", type: model.Emergency });
    self.permits = ko.observableArray().extend({ title: "Tillstånd", type: model.Permit });
    self.hazardPrincipals = ko.observableArray().extend({ title: "Föreståndare", type: model.HazardPrincipal });
    self.estateName = ko.observable().extend({ title: "Fastighetsbeteckning" });

    // Derived properties
    self.buildingClassification = ko.observable().extend({ title: "Byggnadsklass" });

    // Computed
    self.nextLso = ko.pureComputed(function () {
        var planned = self.lsoPlanned();
        return planned ? planned.substr(0, 4) : "";
    });
    self.nextLbe = ko.pureComputed(function () {
        var planned = self.lbePlanned();
        return planned ? planned.substr(0, 4) : "";
    });
    self.nextLsoLbe = ko.pureComputed(function () {
        var planned = self.lsoLbePlanned();
        return planned ? planned.substr(0, 4) : "";
    });

    // Required if changed
    self.name.extend({ requiredIfChanged: { property: self.name } });
    self.address.extend({ requiredIfChanged: { property: self.address } });
}
model.Object.prototype.toJSON = function () {
    var copy = ko.toJS(this);
    delete copy.nextLso;
    delete copy.nextLbe;
    delete copy.nextLsoLbe;
    return copy;
};

model.MeetingHall = function () {
    var self = this;
    self.id = ko.observable().extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.coreObjectId = ko.observable().extend({ title: "ObjID", integer: true, defaultValue: 0 });
    self.name = ko.observable().extend({ title: "Namn", maxLength: 50 });
    self.capacity = ko.observable().extend({ title: "Antal personer", integer: true, defaultValue: 1 });

    // Required if changed
    self.name.extend({ requiredIfChanged: { property: self.name } });
    self.capacity.extend({ requiredIfChanged: { property: self.capacity } });
}

model.InspectionGroup = function () {
    var self = this;
    self.id = ko.observable().extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.name = ko.observable().extend({ title: "Besiktningsgrupp", maxLength: 50 });
}

model.Alarm = function () {
    var self = this;
    self.id = ko.observable().extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.name = ko.observable().extend({ title: "Objektsnamn", maxLength: 100 });
    self.installationNo = ko.observable().extend({ title: "Anläggningsnummer", integer: true });
}

model.Tariff = function () {
    var self = this;
    self.id = ko.observable().extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.name = ko.observable().extend({ title: "Tariff", maxLength: 20 });
    self.description = ko.observable().extend({ title: "Anteckning", maxLength: 255 });
}

model.Contact = function () {
    var self = this;
    self.id = ko.observable().extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.name = ko.observable().extend({ title: "Namn", maxLength: 75 });
    self.firstName = ko.observable().extend({ title: "Förnamn", maxLength: 75 });
    self.lastName = ko.observable().extend({ title: "Efternamn", maxLength: 75 });
    self.position = ko.observable().extend({ title: "Befattning", maxLength: 50 });
    self.ssn = ko.observable().extend({ title: "Personnummer", maxLength: 13 });
    self.email = ko.observable().extend({ title: "E-post", maxLength: 100 });
    self.phoneWork = ko.observable().extend({ title: "Telefon Arbete", maxLength: 40 });
    self.phoneOnCall = ko.observable().extend({ title: "Telefon Jour", maxLength: 40 });
    self.phoneHome = ko.observable().extend({ title: "Telefon Hem", maxLength: 40 });
    self.mobile = ko.observable().extend({ title: "Telefon Mobil", maxLength: 40 });

    var setName = function () {
        var firstName = self.firstName() || "";
        var lastName = self.lastName() || "";
        self.name(firstName + (firstName && lastName ? " " : "") + lastName);
    }
    self.firstName.subscribe(setName);
    self.lastName.subscribe(setName);

    // Required if changed
    self.lastName.extend({ requiredIfChanged: { property: self.lastName } });
}

model.Organisation = function () {
    var self = this;
    self.id = ko.observable().extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.name = ko.observable().extend({ title: "Namn", maxLength: 140 });
    self.attention = ko.observable().extend({ title: "c/o", maxLength: 140 });
    self.ssn = ko.observable().extend({ title: "Orgnummer", maxLength: 25 });
    self.addressVisit = ko.observable().extend({ title: "Besökadress", maxLength: 215 });
    self.addressPostal = ko.observable().extend({ title: "Adress", maxLength: 215 });
    self.addressZipCode = ko.observable().extend({ title: "Postnummer", maxLength: 9 });
    self.addressCity = ko.observable().extend({ title: "Ort", maxLength: 215 });
    self.addressInvoice = ko.observable().extend({ title: "Adress", maxLength: 215 });
    self.addressInvoiceZipCode = ko.observable().extend({ title: "Postnummer", maxLength: 9 });
    self.addressInvoiceCity = ko.observable().extend({ title: "Ort", maxLength: 215 });
    self.phone = ko.observable().extend({ title: "Växeln", maxLength: 40 });
    self.altPhone = ko.observable().extend({ title: "Alt. Telefon", maxLength: 40 });
    self.email = ko.observable().extend({ title: "E-post", maxLength: 100 });
    self.fax = ko.observable().extend({ title: "Faxnummer", maxLength: 40 });
    self.note = ko.observable().extend({ title: "Anteckningar", maxLength: 255 });

    // External data
    self.roles = ko.observableArray().extend({ title: "Organisationstyper", type: model.OrganisationRole });
    self.contacts = ko.observableArray().extend({ title: "Kontaktpersoner", type: model.Contact });

    // Required if changed
    self.name.extend({ requiredIfChanged: { property: self.name } });
}

model.OrganisationRole = function () {
    var self = this;
    self.id = ko.observable().extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.role = ko.observable().extend({ title: "Typ", maxLength: 30 });
}

model.Emergency = function () {
    var self = this;
    self.id = ko.observable().extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.alarmNo = ko.observable().extend({ title: "Larmnr", maxLength: 10 });
    self.alarmTime = ko.observable().extend({ title: "Larm till rtj", maxLength: 22 });

    // Derived properties
    self.emergencyType = ko.observable().extend({ title: "Händelse" });
}

model.Permit = function () {
    var self = this;
    self.id = ko.observable().extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.name = ko.observable().extend({ title: "Avseende", maxLength: 75 });
    self.fromDate = ko.observable().extend({ title: "Fr.o.m.", maxLength: 10 });
    self.toDate = ko.observable().extend({ title: "T.o.m.", maxLength: 10 });

    // Derived properties
    self.permitType = ko.observable().extend({ title: "Tillståndstyp" });
    self.goodsType = ko.observable().extend({ title: "Varor" });
    self.goodsAmount = ko.observable().extend({ title: "Antal", number: true });
}

model.HazardPrincipal = function () {
    var self = this;
    self.id = ko.observable().extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.personId = ko.observable().extend({ title: "", integer: true });
    self.objectId = ko.observable().extend({ title: "", integer: true });
    self.responsibilityExplosives = ko.observable().extend({ title: "Expl vara", bool: true });
    self.responsibilityFireHazard = ko.observable().extend({ title: "Brf vara", bool: true });
    self.note = ko.observable().extend({ title: "Föreståndare/ersättare", maxLength: 35 });

    // Derived properties
    self.name = ko.observable().extend({ title: "Namn" });
}