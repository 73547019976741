var vm = vm || {};

vm.InspectionNarvarande = function () {
    vm.Base.call(this);
    this.alwaysEditing(true);

    this.inspection = dataservice.object.data().inspection;
    this.inspectionContacts = dataservice.object.data().inspectionContacts;
    this.objectContactsAsInspectionContacts = dataservice.object.objectContactsAsInspectionContacts;
    this.newContact = ko.validatedObservable(undefined, { deep: true, observable: true, live: true }).extend({ type: model.InspectionContact });
    this.newContact(new (this.newContact.type));
    this.editContact = ko.validatedObservable(undefined, { deep: true, observable: true, live: true });

    this.selectableContacts = ko.pureComputed(function () {
        var contacts = this.inspectionContacts();
        return ko.utils.arrayFilter(this.objectContactsAsInspectionContacts(), function (orgContact) {
            return !ko.utils.arrayFirst(contacts, function (contact) { return contact.contactId() === orgContact.contactId() });
        });
    }, this);
}

vm.InspectionNarvarande.prototype = Object.create(vm.Base.prototype);
vm.InspectionNarvarande.prototype.constructor = vm.InspectionNarvarande;

vm.InspectionNarvarande.prototype.addExistingContact = function (contact) {
    this.inspectionContacts.push(contact);
}

vm.InspectionNarvarande.prototype.addNewContact = function () {
    this.inspectionContacts.push(this.newContact());
    this.newContact(new (this.newContact.type));
}

vm.InspectionNarvarande.prototype.removeContact = function (contact) {
    this.inspectionContacts.remove(contact);
    if (this.editContact() === contact)
        this.editContact(undefined);
}

vm.InspectionNarvarande.prototype.canEditContact = function (contact) {
    return contact.contactId() === 0 && !this.editContact();
}

vm.InspectionNarvarande.prototype.editCustomContact = function (contact) {
    this.editOriginal = ko.toJS(contact);
    this.editContact(contact);
}

vm.InspectionNarvarande.prototype.replaceEditContact = function () {
    this.editContact(undefined);
}

vm.InspectionNarvarande.prototype.cancelEditContact = function () {
    this.editContact().name(this.editOriginal.name);
    this.editContact().organisation(this.editOriginal.organisation);
    this.editContact().phone(this.editOriginal.phone);
    this.editContact().email(this.editOriginal.email);
    this.editContact(undefined);
}

vm.InspectionNarvarande.prototype.submit = function () {
    vm.global.isBusy(true);
    this.newContact(new (this.newContact.type));
    this.editContact(undefined);
    return dataservice.object.updateInspectionContacts()
        .then(function () {
            vm.global.addNotification("Närvarande har sparats.", "success");
            vm.global.isBusy(false);
        })
        .catch(function (e) {
            vm.global.addNotification("Det gick inte att spara, vänligen försök igen.", "error");
            vm.global.isBusy(false);
            throw e;
        });
}

vm.InspectionNarvarande.prototype.rollback = function () {
    vm.Base.prototype.rollback.call(this);
    this.newContact(new (this.newContact.type));
    this.editContact(undefined);
}

vm.InspectionNarvarande.prototype.canNavigateAway = function () {
    var isDirty = this.isDirty();
    if (isDirty) {
        vm.global.addNotification("Du har gjort ändringar som du behöver spara eller ångra innan det går att byta vy.", "error");
    }
    return !isDirty;
}

vm.InspectionNarvarande.prototype.canNavigateTo = function () {
    return this.inspection() && this.inspection().id() !== 0;
}

vm.InspectionNarvarande.prototype.init = function (params) {
    return vm.Base.prototype.init.call(this, params)
        .catch(function (e) {
            vm.global.addNotification("Det gick inte att hämta tillsynen.", "error");
            throw e;
        });
}

vm.inspectionNarvarande = new vm.InspectionNarvarande();
