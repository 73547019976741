var model = model || {};

model.Case = function () {
    var self = this;

    self.id = ko.observable().extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.registrationNumber = ko.observable().extend({ title: "Diarienummer" });
    self.category = ko.observable().extend({ title: "Rubrik" });
    self.startDate = ko.observable().extend({ title: "Start" });
    self.endDate = ko.observable().extend({ title: "Slut" });

    // Related data
    self.caseWorker = ko.observable().extend({ type: model.CaseWorker });
    self.events = ko.observableArray().extend({ title: "Händelser", type: model.Event });
};

model.Event = function () {
    var self = this;

    self.id = ko.observable().extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.category = ko.observable().extend({ title: "Rubrik" });
    self.date = ko.observable().extend({ title: "Datum" });
    self.documents = ko.observableArray().extend({ title: "Handlingar" , type: model.EventDocument });
};

model.EventDocument = function () {
    var self = this;

    self.id = ko.observable().extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.path = ko.observable().extend({ title: "Sökväg" });
    self.description = ko.observable().extend({ title: "Beskrivning", maxLength: 255 });

    // Computed
    self.shortDescription = ko.pureComputed(function () {
        var description = self.description();
        if (!description) return;
        description = description.substring(0, 120);
        return description.length < self.description().length ? description + "..." : description;
    });
}
model.EventDocument.prototype.toJSON = function () {
    var copy = ko.toJS(this);
    delete copy.shortDescription;
    return copy;
};