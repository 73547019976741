var dataservice = dataservice || {};

dataservice.Search = function () {
    dataservice.Base.call(this);

    // Search data that we should be able to rollback and/or check dirty state of
    this.data({
        selectedCaseWorkers: ko.observableArray().extend({ type: model.CaseWorker }),
        searchText: ko.observable()
    });

    // Other search data
    this.caseWorkers = ko.observableArray().extend({ type: model.CaseWorker });
    this.searchResults = ko.observableArray().extend({ type: model.SearchHit });
}

dataservice.Search.prototype = Object.create(dataservice.Base.prototype);
dataservice.Search.prototype.constructor = dataservice.Search;

utils.extend(dataservice.Search.prototype, function () {
    var
        getCaseWorkers = function () {
            var self = this;

            self.caseWorkers.removeAll();
            return dataapi.search.getCaseWorkers()
                .then(function (result) {
                    self.copyObjectIntoObservable(result, self.caseWorkers);
                    self.commit();
                });
        },
        searchUsingText = function () {
            var self = this;
            dataapi.search.abort();
            return dataapi.search.usingText(this.data().searchText(), this.data().selectedCaseWorkers())
                .then(function (result) {
                    self.searchResults([]);
                    self.copyObjectIntoObservable(result, self.searchResults);
                    self.commit();
                });
        },
        searchUsingPosition = function () {
            var self = this;
            dataapi.search.abort();
            return dataapi.search.usingPosition(this.data().searchText(), this.data().selectedCaseWorkers())
                .then(function (result) {
                    self.searchResults([]);
                    self.copyObjectIntoObservable(result, self.searchResults);
                    self.commit();
                });
        },
        useCachedObjectsForSearchResult = function () {
            var self = this;
            offlineCache.getCachedObjects()
                .then(function (cachedObjects) {
                    var searchResultType = self.searchResults.type;
                    var workers = self.caseWorkers();
                    var searchResults = [];

                    ko.utils.arrayForEach(cachedObjects, function (item) {
                        if (!item) return;
                        var caseWorkerSignature = utils.firstOrDefault(workers, function (cw) {
                            return cw.id() === item.caseWorkerId;
                        }, { signature: "" }).signature;

                        var searchResult = (new searchResultType)
                            .objectId(item.id)
                            .objectName(item.name)
                            .estateName(item.estateName)
                            .address(item.address)
                            .caseWorker(ko.unwrap(caseWorkerSignature))
                            .isFromCache(true);
                        searchResults.push(searchResult);
                    });

                    dataapi.search.abort();
                    self.searchResults(searchResults);
                    self.commit();
                });
        },
        abortSearch = dataapi.search.abort;

    return {
        getCaseWorkers: getCaseWorkers,
        searchUsingText: searchUsingText,
        searchUsingPosition: searchUsingPosition,
        useCachedObjectsForSearchResult: useCachedObjectsForSearchResult,
        abortSearch: abortSearch
    };
}());

dataservice.search = new dataservice.Search();
