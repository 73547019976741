var dataapi = dataapi || {};

dataapi.object = {
    // Get
    getObjectComplete: function (id) {
        return utils.query("api/objects/" + id + "/complete");
    },
    getObject: function (id) {
        return utils.query("api/objects/" + id);
    },
    getInspectionCases: function (id) {
        return utils.query("api/objects/" + id + "/inspectioncases");
    },
    getInspectionHistory: function (id) {
        return utils.query("api/objects/" + id + "/inspectionhistory");
    },
    getSafetyReports: function (id) {
        return utils.query("api/objects/" + id + "/safetyreports");
    },
    getInspectionGroups: function () {
        return utils.query("api/inspectiongroups");
    },
    getOrganisationRoles: function () {
        return utils.query("api/organisationroles");
    },
    getInspection: function (id) {
        return utils.query("api/objects/" + id + "/inspections");
    },
    getInspectionContacts: function (id) {
        return utils.query("api/objects/" + id + "/inspectionattendees");
    },
    getDocumentArchive: function () {
        return utils.query("api/archive");
    },
    getCaseWorkers: function () {
        return utils.query("api/caseworkers");
    },

    // Create/update
    updateObject: function (id, data) {
        var dataJson = JSON.stringify(data);
        return utils.query("api/objects/" + id, {
            method: "PUT",
            data: dataJson
        });
    },
    updateInspectionHistory: function (id, data) {
        var dataJson = JSON.stringify(data);
        return utils.query("api/objects/" + id + "/inspectionhistory", {
            method: "PUT",
            data: dataJson
        });
    },
    createInspection: function (id, data) {
        var dataJson = JSON.stringify(data);
        return utils.query("api/objects/" + id + "/inspections", {
            method: "POST",
            data: dataJson
        });
    },
    updateInspection: function (id, data) {
        var dataJson = JSON.stringify(data);
        return utils.query("api/objects/" + id + "/inspections", {
            method: "PUT",
            data: dataJson
        });
    },
    updateInspectionContacts: function (id, data) {
        var dataJson = JSON.stringify(data);
        return utils.query("api/objects/" + id + "/inspectionattendees", {
            method: "PUT",
            data: dataJson
        });
    },
    removeInspection: function (id) {
        return utils.query("api/objects/" + id + "/inspections", {
            method: "DELETE"
        });
    }
}
