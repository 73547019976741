// TODO: Refactor this

var server = (function () {
    var
        online = ko.observable(true),
        forceOffline = ko.observable(false),
        isOnline = ko.pureComputed({
            read: function () {
                if (forceOffline()) return false;
                return online();
            },
            write: online
        }),
        awaitingPoll = false,
        timerId = 0,
        ping = function () {
            if (!awaitingPoll) {
                awaitingPoll = true;
                utils.ajax(config.serverPingOptions.url, { timeout: config.serverPingOptions.timeout })
                    .then(function () {
                        online(true);
                        awaitingPoll = false;
                    }, function () {
                        online(false);
                        awaitingPoll = false;
                    });
            }
        },
        startPolling = function () {
            if (timerId !== 0)
                clearInterval(timerId);
            timerId = setInterval(ping, config.serverPingOptions.interval);
            ping();
        },
        init = function () {
            forceOffline(window.location.search.indexOf("offline") !== -1); // If query param contains offline, force working offline
            $.ajaxSetup({ cache: false });
            startPolling();
        };

    return {
        isOnline: isOnline,
        forceOffline: forceOffline,
        init: init
    }
})();

var offlineCache = (function () {
    var
        getItem = function (key) {
            key = key.toString().toLowerCase();
            return localforage.getItem(key)
                .catch(function (err) {
                    utils.log("Error: localforage.getItem for key: " + key + " returned: ", err);
                });
        },
        setItem = function (key, value) {
            key = key.toString().toLowerCase();
            return localforage.setItem(key, value)
                .catch(function (err) {
                    utils.log("Error: localforage.setItem for key: " + key + " returned: ", err);
                });
        },
        removeItem = function (key) {
            key = key.toString().toLowerCase();
            return localforage.removeItem(key)
                .catch(function (err) {
                    utils.log("Error: localforage.removeItem for key: " + key + " returned: ", err);
                });
        },
        clear = function () {
            return localforage.clear()
                .catch(function (err) {
                    utils.log("Error: localforage.clear returned: ", err);
                });
        }
        
        cacheMap = [
            // Login
            {
                route: "api/security/logininfo",
                store: "logininfo"
            },
            {
                route: "api/security/logout",
                store: "logout",
                afterPost: function (requestResult) {
                    requestResult.then(function () {
                        return getItem("logininfo")
                            .then(function (logininfo) {
                                logininfo.current.isLoggedIn = false;
                                logininfo.current.displayName = "";
                                logininfo.current.isAdmin = false;
                                return setItem("logininfo", logininfo)
                                    .then(function () { return requestResult; });
                            });
                    });
                }
            },
            // Search
            {
                route: "api/caseworkers",
                store: "caseworkers"
            },
            // Object
            {
                route: "api/objects/:id",
                store: "object",
                storeKey: "id",
                sendOriginal: true,
                sendWhenSyncing: true
            },
            {
                route: "api/objects/:id/inspectioncases",
                store: "objectinspectioncases",
                storeKey: "id"
            },
            {
                route: "api/objects/:id/inspectionhistory",
                store: "objectinspectionhistory",
                storeKey: "id",
                sendWhenSyncing: true
            },
            {
                route: "api/objects/:id/safetyreports",
                store: "objectsafetyreports",
                storeKey: "id"
            },
            {
                route: "api/inspectiongroups",
                store: "inspectiongroups"
            },
            {
                route: "api/organisationroles",
                store: "organisationroles"
            },
            // Inspection
            {
                route: "api/objects/:id/inspections",
                store: "inspection",
                storeKey: "id",
                sendOriginal: true,
                sendWhenSyncing: true,
                beforeGet: function (store) {
                    // 404 if cached inspection is closed, this can only happen if application went offline after sending close inspection but before receiving response
                    var cachedInspection = store["inspection"];
                    if (cachedInspection && cachedInspection.original && cachedInspection.current && !cachedInspection.original.date && cachedInspection.current.date) {
                        return Promise.reject({ status: 404 }, "404", "Not Found");
                    }
                },
                /*beforePut: function (store) {
                    // Closing inspection? If so copy answers to inspection history
                    var inspection = store["inspection"];
                    if (inspection.original && inspection.current && inspection.original.date === null && inspection.current.date !== null) {
                        // Get history for this object from store or create it if none
                        var history = store["objectinspectionhistory"];
                        if (!Array.isArray(history.current))
                            history.current = [];
                        var anyAdded = false;

                        // Copy answers from inspection to history
                        ko.utils.arrayForEach(inspection.current.checkLists, function (checklist) {
                            ko.utils.arrayForEach(checklist.sections, function (section) {
                                ko.utils.arrayForEach(section.questions, function (question) {
                                    // Find question in history
                                    var historyQuestion = utils.firstOrDefault(history.current, function (h) {
                                        return h.questionId === question.id;
                                    });
                                    if (historyQuestion) {
                                        // Set is from latest to false for all exising answers in history
                                        ko.utils.arrayForEach(historyQuestion.answers, function (answer) {
                                            answer.isFromLatestInspection = false;
                                        });
                                    }
                                    // Next question if inspection has no warning answers for this one
                                    if (!ko.utils.arrayFirst(question.answers, function (a) { return a.warning; })) return;
                                    // Create question in history if none
                                    if (!historyQuestion) {
                                        historyQuestion = { sectionId: section.id, questionId: question.id, section: section.section, questionText: question.questionText, answers: [] };
                                        history.current.push(historyQuestion);
                                    }
                                    // Add all warning answers to question and fill answer history specific props
                                    ko.utils.arrayForEach(question.answers, function (answer) {
                                        if (!answer.warning) return;
                                        answer.warningCorrected = false;
                                        answer.isOverdue = answer.actionDeadline < utils.today();
                                        answer.correctionDate = null;
                                        answer.correctionMemo = "";
                                        answer.inspectionDate = inspection.current.date;
                                        answer.isFromLatestInspection = true;
                                        historyQuestion.answers.push(answer);
                                    });
                                    anyAdded = true;
                                });
                            });
                        });

                        if (anyAdded) {
                            history.current.sort(function (left, right) {
                                if (left.sectionId === right.sectionId) {
                                    return left.questionId === right.questionId ? 0 : (left.questionId < right.questionId ? -1 : 1);
                                }
                                return left.sectionId < right.sectionId ? -1 : 1;
                            });
                        }
                    }
                },*/
                afterPut: function (requestResult, store) {
                    return requestResult.then(function (inspection) {
                        // Update any answer ids of answers not in database to unique negative numbers (this happens when offline)
                        // Needed because answer id is used to identify the answer in some views
                        var uniqueId = -1;
                        var anyChanged = false;
                        ko.utils.arrayForEach(inspection.checkLists, function (checklist) {
                            ko.utils.arrayForEach(checklist.sections, function (section) {
                                ko.utils.arrayForEach(section.questions, function (question) {
                                    ko.utils.arrayForEach(question.answers, function (answer) {
                                        if (answer.id <= 0) {
                                            answer.id = uniqueId;
                                            uniqueId--;
                                            anyChanged = true;
                                        }
                                    });
                                });
                            });
                        });
                        // Update cache with new inspection
                        if (anyChanged) {
                            store["inspection"].current = inspection;
                        }
                        // If inspecion was closed and this got synced return null as result so object is removed from cache
                        var cachedInspection = store["inspection"];
                        if (cachedInspection && cachedInspection.synced === true && cachedInspection.original && cachedInspection.current && cachedInspection.original.date && cachedInspection.current.date) {
                            return null;
                        }
                        return inspection;
                    });
                }
            },
            {
                route: "api/objects/:id/inspectionattendees",
                store: "inspectionattendees",
                storeKey: "id",
                storeElementType: Array,
                sendOriginal: true,
                sendWhenSyncing: true
            },
            {
                route: "api/archive",
                store: "documentarchive"
            }
        ],

        getCachedObjects = function () {
            var currentList = [];
            return localforage.iterate(function (store) {
                    if (store && typeof store["object"] === "object") {
                        currentList.push(store["object"].current);
                    }
                })
                .then(function () {
                    return currentList;
                });
        },

        removeObjectFromCache = function (key) {
            return removeItem(key);
        },

        hasObjectUnsyncedChanges = function (key) {
            return getItem(key)
                .then(function (store) {
                    var unsynced = utils.firstOrDefault(store, function (storeItem, storeProp) {
                        var cacheMapEntry = utils.firstOrDefault(cacheMap, function (cacheItem) {
                            return cacheItem.store === storeProp;
                        });
                        return cacheMapEntry && cacheMapEntry.sendWhenSyncing && storeItem.synced === false;
                    });
                    if (unsynced) return true;
                });
        },

        hasUnsyncedChanges = ko.observable(false),
        setHasUnsyncedChanges = function () {
            return localforage.iterate(function (store, key) {
                    var unsynced = utils.firstOrDefault(cacheMap, function (cacheItem) {
                        return cacheItem.store === key && cacheItem.sendWhenSyncing && store.synced === false;
                    });
                    if (unsynced) return true;
                    // Not a common store, maybe it is an object store, check entries in it
                    unsynced = utils.firstOrDefault(store, function (storeItem, storeProp) {
                        var cacheMapEntry = utils.firstOrDefault(cacheMap, function (cacheItem) {
                            return cacheItem.store === storeProp;
                        });
                        return cacheMapEntry && cacheMapEntry.sendWhenSyncing && storeItem.synced === false;
                    });
                    if (unsynced) return true;
                    return undefined;
                })
                .then(function (unsynced) {
                    hasUnsyncedChanges(unsynced);
                });
        },

        getUnsyncedChanges = function () {
            var result = [];
            return localforage.iterate(function (store, key) {
                    var next = ko.utils.arrayFirst(cacheMap, function (cacheItem) {
                        if (cacheItem.store === key && cacheItem.sendWhenSyncing && store.synced === false) {
                            result.push({ url: cacheItem.route, data: store.current });
                            return true;
                        }
                    });
                    if (next) return;
                    // Not a common store, maybe it is an object store, check entries in it
                    ko.utils.objectForEach(store, function (storeProp, storeItem) {
                        utils.firstOrDefault(cacheMap, function (cacheItem) {
                            if (cacheItem.store === storeProp && cacheItem.sendWhenSyncing && storeItem.synced === false) {
                                var url = cacheItem.route.replace(/(:[\w\d]+)/, key);
                                result.push({ url: url, data: storeItem.current });
                            }
                        });
                    });
                })
                .then(function () {
                    return result;
                });
        },

        syncChanges = function (step, total) {
            var sendChanges = function (change) {
                step(step() + 1);
                return utils.query(change.url, { method: "PUT", data: JSON.stringify(change.data) });
            };

            return getUnsyncedChanges()
                .then(function (unsyncedChanges) {
                    if (!unsyncedChanges || unsyncedChanges.length === 0) return Promise.reject();
                    total(unsyncedChanges.length);
                    step(-1);

                    var change = unsyncedChanges[0];
                    var result = sendChanges(change);
                    for (var i = 1; i < unsyncedChanges.length; i++) {
                        result = result.then(sendChanges.bind(null, unsyncedChanges[i]));
                    }
                    result.then(function () {
                        step(total());
                    });
                    return result;
                });
        },

        getCacheMapEntryForUrl = function (url) {
            return utils.firstOrDefault(cacheMap, function (item) {
                var route = item.route.replace(/:([\w\d]+)/g, "([^\/]+)");
                var routeRegExp = new RegExp("^" + route + "$");
                return routeRegExp.test(url);
            });
        },

        getParameterValues = function (route, url) {
            // Get parameter names from route
            var paramNamesRegexp = /:([\w\d]+)/g,
                paramNamesMatch,
                paramNames = [];
            while ((paramNamesMatch = paramNamesRegexp.exec(route)) !== null) {
                paramNames.push(paramNamesMatch[1]);
            }

            // Get parameter values from url
            var paramValuesRegex = new RegExp(route.replace(/:([\w\d]+)/g, "([^\/]+)") + "$"),
                paramValuesMatch = paramValuesRegex.exec(url),
                params = {};
            if (paramValuesMatch) {
                paramValuesMatch.shift(); // Remove first match (=whole url)
                ko.utils.arrayForEach(paramValuesMatch, function (value, index) {
                    params[paramNames[index]] = value;
                });
            }

            return params;
        },

        query = function (url, options) {
            // Get entry from cache map
            var cacheMapEntry = getCacheMapEntryForUrl(url);
            // If not found then this is not a call we cache, just send it
            if (!cacheMapEntry) {
                if (!server.isOnline()) // If offline, fail directly
                    return Promise.resolve({ status: 503 }, "503", "Offline");
                return utils.ajax(url, options);
            }

            // Get parameter values from url
            var params = getParameterValues(cacheMapEntry.route, url);

            // Construct key to get from cache
            var storeKey = cacheMapEntry.storeKey ? params[cacheMapEntry.storeKey] : cacheMapEntry.store;

            // Get store and value from cache if any, else create them
            return getItem(storeKey)
                .then(function (store) {
                    var object;
                    if (!store && cacheMapEntry.storeKey) {
                        store = {};
                    } else if (!store) {
                        store = {
                            original: cacheMapEntry.storeElementType ? new (cacheMapEntry.storeElementType) : null,
                            current: cacheMapEntry.storeElementType ? new (cacheMapEntry.storeElementType) : null,
                            synced: null
                        };
                    }
                    if (cacheMapEntry.storeKey && !store[cacheMapEntry.store]) {
                        store[cacheMapEntry.store] = {
                            original: cacheMapEntry.storeElementType ? new (cacheMapEntry.storeElementType) : null,
                            current: cacheMapEntry.storeElementType ? new (cacheMapEntry.storeElementType) : null,
                            synced: null
                        };
                    }
                    object = cacheMapEntry.storeKey ? store[cacheMapEntry.store] : store;

                    var requestResult = null;
                    var method = options.method.toUpperCase();
                    switch (method) {
                    case "GET":
                        var doGet = function () {
                            var result;
                            if (!server.isOnline()) {
                                // Respond with data in cache when offline or fail request if data not present
                                if (object.current !== null && object.current !== 404) // Return cached data
                                    result = Promise.resolve(object.current, "200", { status: 200, statusText: "OK" });
                                else if (object.current === 404) // Return cached 404
                                    result = Promise.reject({ status: 404 }, "404", "Not Found");
                                else // Result not in cache, fail request
                                    result = Promise.reject({ status: 503 }, "503", "Offline");
                            } else {
                                result = utils.ajax(url, options)
                                    .then(
                                        function (response, status, xhr) {
                                            // Save response in cache if no unsynced changes
                                            if (object.synced !== false) {
                                                object.original = cacheMapEntry.sendOriginal ? response : null;
                                                object.current = response || null;
                                                object.synced = true;
                                            }
                                            return Promise.resolve(object.current, status, xhr);
                                        },
                                        function (response, status, error) {
                                            // Check for real failed response
                                            if (response && response.status === 404) {
                                                // Plain not found, we store these too
                                                // Save response in cache if no unsynced changes
                                                if (object.synced !== false) {
                                                    object.original = cacheMapEntry.sendOriginal ? 404 : null;;
                                                    object.current = 404;
                                                    object.synced = true;
                                                }
                                                if (object.current !== 404)
                                                    return Promise.resolve(object.current, "200", { status: 200, statusText: "OK" });
                                                else
                                                    return Promise.reject(response, status, error);
                                            } else if (response && response.status >= 300) { // use this when using fiddler: && response.status <= 500) {
                                                // Real server error
                                                return Promise.reject(response, status, error);
                                            } else {
                                                // Not a server response, we went offline after placing the ajax call
                                                server.isOnline(false);
                                                // Respond with data in cache or fail request if data not present
                                                if (object.current !== null && object.current !== 404) // Return cached data
                                                    return Promise.resolve(object.current, "200", { status: 200, statusText: "OK" });
                                                else if (object.current === 404) // Return cached 404
                                                    return Promise.reject({ status: 404 }, "404", "Not Found");
                                                else // Result not in cache, fail request
                                                    return Promise.reject({ status: 503 }, "503", "Offline");
                                            }
                                        });
                            }
                            return result;
                        }

                        // Action before GET
                        if (cacheMapEntry.beforeGet) {
                            requestResult = cacheMapEntry.beforeGet(store);
                        }
                        // GET
                        if (requestResult)
                            requestResult = requestResult.then(doGet);
                        else
                            requestResult = doGet();
                        // Action after GET
                        if (cacheMapEntry.afterGet) {
                            requestResult = requestResult.then(cacheMapEntry.afterGet.bind(null, requestResult, store), cacheMapEntry.afterGet.bind(null, requestResult, store));
                        }

                        break;
                    case "POST":
                    case "PUT":
                        object.current = options.data ? JSON.parse(options.data) : null;
                        var doPostPut = function () {
                            var result;
                            if (!server.isOnline()) {
                                // We're offline, save data in cache and answer ok
                                object.synced = false;
                                result = Promise.resolve(object.current, "200", { status: 200, statusText: "OK" });
                            } else {
                                if (cacheMapEntry.sendOriginal) {
                                    var dataToSend = { original: object.original, current: object.current };
                                    options.data = JSON.stringify(dataToSend);
                                }
                                result = utils.ajax(url, options)
                                    .then(
                                        function (response, status, xhr) {
                                            // Save response in cache
                                            object.original = cacheMapEntry.sendOriginal ? response : null;
                                            object.current = response || null;
                                            object.synced = true;
                                            return Promise.resolve(response, status, xhr);
                                        },
                                        function (response, status, error) {
                                            if (response && response.status >= 300) { // use this when using fiddler: && response.status <= 500) {
                                                // Real server error
                                                return Promise.reject(response, status, error);
                                            } else {
                                                // We went offline after placing the ajax call, save data in cache and answer ok
                                                server.isOnline(false);
                                                object.synced = false;
                                                return Promise.resolve(object.current, "200", { status: 200, statusText: "OK" });
                                            }
                                        });
                            }
                            return result;
                        }

                        // Action before POST/PUT
                        if (cacheMapEntry.beforePost && method === "POST") {
                            requestResult = cacheMapEntry.beforePost(store);
                        } else if (cacheMapEntry.beforePut && method === "PUT") {
                            requestResult = cacheMapEntry.beforePut(store);
                        }
                        // POST/PUT
                        if (requestResult)
                            requestResult = requestResult.then(doPostPut);
                        else
                            requestResult = doPostPut();
                        // Action after POST/PUT
                        if (cacheMapEntry.afterPost && method === "POST") {
                            requestResult = requestResult.then(cacheMapEntry.afterPost.bind(null, requestResult, store), cacheMapEntry.afterPost.bind(null, requestResult, store));
                        } else if (cacheMapEntry.afterPut && method === "PUT") {
                            requestResult = requestResult.then(cacheMapEntry.afterPut.bind(null, requestResult, store), cacheMapEntry.afterPut.bind(null, requestResult, store));
                        }

                        break;
                    case "DELETE":
                        // For now, don't do anything with cache for DELETEs
                        if (!server.isOnline()) // If offline, fail directly
                            return Promise.resolve({ status: 503 }, "503", "Offline");
                        return utils.ajax(url, options);
                    }

                    var done = function (afterResult) {
                        var setItemDone = function () {
                            setHasUnsyncedChanges();
                            return requestResult;
                        }
                        // If some after get/post/put returns null, remove store from cache
                        return afterResult === null ? removeItem(storeKey) : setItem(storeKey, store).then(setItemDone, setItemDone);
                    };
                    return requestResult.then(done, done);
                });
        },

        init = function () {
            localforage.config({
                name: "RxCoreInspection"
            });
        };

    return {
        init: init,

        getCachedObjects: getCachedObjects,
        clearCache: clear,
        removeObjectFromCache: removeObjectFromCache,
        hasObjectUnsyncedChanges: hasObjectUnsyncedChanges,

        hasUnsyncedChanges: hasUnsyncedChanges,
        syncChanges: syncChanges,
        query: query
    }
})();