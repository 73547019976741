var dataapi = dataapi || {};

dataapi.login = {
    login: function (data) {
        data.grant_type = "password";
        return utils.query("token", {
            method: "POST",
            data: data,
            contentType: "application/x-www-form-urlencoded; charset=UTF-8"
        }, true);
    },
    getLoginInfo: function () {
        return utils.query("api/security/logininfo");
    }
}
