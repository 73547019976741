var vm = vm || {};

vm.ObjectTillsyn = function () {
    vm.ObjectBase.call(this);

    this.inspectionGroups = dataservice.object.inspectionGroups;
    this.inspectionCases = dataservice.object.inspectionCases;

    this.contactPersons = ko.pureComputed(function () {
        var contacts = [];
        var inspectionContactId = this.object().inspectionContactId();
        var foundInspectionContact = false;

        ko.utils.arrayForEach(this.object().organisations(), function (organisation) {
            ko.utils.arrayForEach(organisation.contacts(), function (contact) {
                if (inspectionContactId && contact.id() === inspectionContactId)
                    foundInspectionContact = true;
                contacts.push(contact);
            });
        });
        if (!foundInspectionContact && inspectionContactId && this.object().inspectionContact()) {
            contacts.push(this.object().inspectionContact());
        }

        return contacts;
    }, this);
}

vm.ObjectTillsyn.prototype = Object.create(vm.ObjectBase.prototype);
vm.ObjectTillsyn.prototype.constructor = vm.ObjectTillsyn;

vm.ObjectTillsyn.prototype.hrefForDocument = function (documentId, eventId) {
    var token = sessionStorage.getItem("authorization_token"),
        url = "api/events/" + eventId() + "/document/" + documentId() + (token ? "?token=" + token : "");
    return url;
}

vm.objectTillsyn = new vm.ObjectTillsyn();
