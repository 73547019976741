// TODO: Refactor this

var navigation = (function () {

    var
        views = [
            /*{
                id: only needed if no view
                name: display name of view
                view: name of view, used to identify the view, used to load template, used to match view in hash
                params: parameters for view, sammyjs syntax
                vm: views model of view. if contains init function it is called when view is activated
                divider: for group == menu. adds divider to menu
                group: navbar = show item in nav bar
                       menu   = show item in nav bar menu (menu item without route/action is shown as header)
                       modal  = this view is a modal
                       view   = this is a view that is not shown in nav
                action: javascript function called when item is selected
                icon: for group == menu. glyphicon to show to right of item
                isDefault: view that is shown if unknown or no view in hash
                enabled: bool or function that returns true/false if view/action should be enabled
                enabledThis: this for enabled function if different than vm
            },*/
            {
                name: "Sök",
                view: "search",
                route: "sok",
                vm: vm.search,
                group: "menu",
                isDefault: true,
                icon: "search"
            },
            {
                id: "object",
                name: "Objekt",
                route: "objekt/:id/information",
                vm: vm.objectInformation,
                group: "menu"
            },
            {
                id: "inspection",
                name: "Tillsyn",
                route: "tillsyn/:id",
                vm: vm.inspection,
                group: "menu"
            },
            {
                id: "divider",
                divider: true,
                group: "menu"
            },
            {
                id: "logout",
                name: ko.pureComputed(function () {
                    return "Logga ut (" + vm.login.user().displayName() + ")";
                }),
                group: "menu",
                action: vm.login.logoutWithCheck,
                icon: "off"
            },
            {
                name: "Login",
                view: "login",
                vm: vm.login,
                group: "modal"
            },
            {
                name: "ImageViewer",
                view: "imageViewer",
                vm: vm.imageViewer,
                group: "modal"
            },
            {
                name: "SyncDialog",
                view: "syncDialog",
                vm: vm.sync,
                group: "modal"
            },
            {
                id: "currentObject",
                name: ko.pureComputed(function () {
                    if (dataservice.object.data().object())
                        return dataservice.object.data().object().name();
                    return null;
                }),
                group: "menu"
            },
            {
                name: "Information",
                view: "objectInformation",
                route: "objekt/:id/information",
                vm: vm.objectInformation,
                group: "navbar"
            },
            {
                name: "Förvaltning",
                view: "objectForvaltning",
                route: "objekt/:id/forvaltning",
                vm: vm.objectForvaltning,
                group: "navbar"
            },
            {
                name: "Tillsyn",
                view: "objectTillsyn",
                route: "objekt/:id/tillsyn",
                vm: vm.objectTillsyn,
                group: "navbar"
            },
            {
                name: "Noteringar",
                view: "objectNoteringar",
                route: "objekt/:id/noteringar",
                vm: vm.objectNoteringar,
                group: "navbar"
            },
            {
                name: "Insatser",
                view: "objectInsatser",
                route: "objekt/:id/insatser",
                vm: vm.objectInsatser,
                group: "navbar"
            },
            {
                name: "Tillstånd",
                view: "objectTillstand",
                route: "objekt/:id/tillstand",
                vm: vm.objectTillstand,
                group: "navbar"
            },
            {
                name: "Redogörelser",
                view: "objectRedogorelser",
                route: "objekt/:id/redogorelser",
                vm: vm.objectRedogorelser,
                group: "navbar"
            },
            {
                name: "Tillsyn",
                view: "inspectionTillsyn",
                route: "tillsyn/:id",
                vm: vm.inspection,
                group: "navbar"
            },
            {
                name: "Närvarande",
                view: "inspectionNarvarande",
                route: "tillsyn/:id/narvarande",
                vm: vm.inspectionNarvarande,
                group: "navbar"
            },
            {
                name: "Kontrollpunkter",
                view: "inspectionKontrollpunkter",
                route: "tillsyn/:id/kontrollpunkter",
                vm: vm.inspectionKontrollpunkter,
                group: "navbar"
            },
            {
                name: "Notering",
                view: "inspectionNotering",
                route: "tillsyn/:id/notering",
                vm: vm.inspectionNotering,
                group: "navbar"
            },
            {
                name: "Sammanställning",
                view: "inspectionSammanstallning",
                route: "tillsyn/:id/sammanstallning",
                vm: vm.inspectionSammanstallning,
                group: "navbar"                
            },
            {
                name: "Dokumentbank",
                view: "inspectionDokumentbank",
                route: "dokumentbank/:id",
                vm: vm.documentArchive,
                group: "navbar"                
            }
        ],

        // Nav items to show for a view
        viewGroups = {
            search: ["logout"],
            object: ["currentObject", "inspection", "divider", "search", "divider", "logout", "objectInformation", "objectForvaltning", "objectTillsyn", "objectNoteringar", "objectInsatser", "objectTillstand", "objectRedogorelser"],
            inspection: ["currentObject", "object", "divider", "search", "divider", "logout", "inspectionTillsyn", "inspectionNarvarande", "inspectionKontrollpunkter", "inspectionNotering", "inspectionSammanstallning", "inspectionDokumentbank"],
            objectInformation: "object",
            objectForvaltning: "object",
            objectTillsyn: "object",
            objectInsatser: "object",
            objectTillstand: "object",
            objectRedogorelser: "object",
            objectNoteringar: "object",
            inspectionTillsyn: "inspection",
            inspectionNarvarande: "inspection",
            inspectionKontrollpunkter: "inspection",
            inspectionNotering: "inspection",
            inspectionSammanstallning: "inspection",
            inspectionDokumentbank: "inspection"
        },

        loadTemplates = function () {
            return new Promise(function (resolve) {
                var loaded = 0;

                function doresolve() {
                    loaded++;
                    if (loaded === views.length)
                        resolve();
                }

                var templatesFolder = config.templatesFolder ? config.templatesFolder.replace(/\/$/, "") + "/" : "";
                ko.utils.arrayForEach(views, function (view) {
                    view.isLoaded = ko.observable();
                    if (view.view) {
                        var templateUrl = templatesFolder + config.templateFilePrefix + view.view + config.templateFileSuffix;
                        utils.ajax(templateUrl).then(function (html) {
                            $("#" + view.view + "Template").html(html);
                            view.isLoaded(true);
                            doresolve();
                        });
                    } else {
                        doresolve();
                    }
                });

            });
        },

        activeNavigationOptions = ko.observableArray(),
        activeView = ko.observable(),
        activeViewParams = ko.observable(),
        activeViewName = ko.observable(),
        activeViewObject = ko.observable(),

        activeViewsForView = function (view) {
            if (!viewGroups[view])
                return null;

            var activeViewNames = viewGroups[viewGroups[view]] || viewGroups[view];
            var activeViews = [];
            ko.utils.arrayForEach(activeViewNames, function (avn) {
                var vw = ko.utils.arrayFirst(views, function (item) {
                    return item.view === avn || item.id === avn;
                });
                if (vw)
                    activeViews.push(vw);
            });

            return activeViews;
        },

        setDocumentTitle = function (viewName) {
            document.title = config.applicationName + (viewName ? " - " + viewName : "");
        },

        sammy = new Sammy.Application(),
        defaultView = "",

        setLocation = function (location) {
            sammy.setLocation(location);
        },

        initRouting = function () {
            var currentLocation;
            sammy.debug = utils.debug;

            // Define route for each view
            ko.utils.arrayForEach(views, function (view) {
                if (view.isDefault)
                    defaultView = view.route;
            });
            ko.utils.arrayForEach(views, function (view) {
                if (view.view && view.route) {
                    var hash = "#/?" + view.route + "/?";

                    sammy.get(hash, function (context) {
                        // Close any drop downs when navigating, else they will stay open
                        $(".dropdown.open .dropdown-toggle").dropdown("toggle");
                        // Close image viewer
                        vm.imageViewer.hide();

                        // Init the new view
                        var initialized;
                        if (view.vm && view.vm.init)
                            initialized = view.vm.init(context.params);

                        var initDone = function () {
                            // Do not navigate to the new view if it is not enabled
                            if (!view.enabled()) {
                                sammy.setLocation(currentLocation || "#/" + defaultView);
                                return;
                            }

                            // Save params
                            activeViewParams(context.params);
                            // Get views that are accessible from the new view
                            var activeViews = activeViewsForView(view.view);
                            activeNavigationOptions(activeViews);
                            // Update document
                            activeView(view.view);
                            setDocumentTitle(view.name);
                            activeViewName(view.name);
                            activeViewObject(view);
                            currentLocation = sammy.getLocation();

                            // Setup window unload to warn for unsaved changes on navigation
                            window.onbeforeunload = undefined;
                            if (view.vm && typeof view.vm.canNavigateAway !== "undefined") {
                                window.onbeforeunload = (function () {
                                    return view.vm.isDirty() ? "Du har gjort ändringar som inte har sparats." : undefined;
                                });
                            }
                        }
                        initialized ? initialized.then(initDone) : initDone();
                    });
                }

                // If enabled function defined (view.enabled or vm.canNavigateTo), make it an observable. If not view is always enabled
                if (typeof view.enabled === "boolean") {
                    var enabledVal = view.enabled;
                    view.enabled = function () { return enabledVal; }
                }
                else if (typeof view.enabled !== "undefined")
                    view.enabled = ko.pureComputed(view.enabled, view.enabledThis || view.vm);
                else if (view.vm && view.vm.canNavigateTo)
                    view.enabled = ko.pureComputed(view.vm.canNavigateTo, view.enabledThis || view.vm);
                else 
                    view.enabled = function () { return true; };

                // Update view action to include view enabled check
                if (view.action) {
                    var action = view.action;
                    view.action = function () {
                        var currentView = activeViewObject();
                        if ((!currentView || !currentView.vm || !currentView.vm.canNavigateAway || currentView.vm.canNavigateAway()) && view.enabled())
                            return action();
                        else
                            return false;
                    }
                }
            });

            // If no route matches a view, show default view
            sammy.get(/^((?!(windowsauth)).)*$/i, function () {
                sammy.setLocation("#/" + defaultView);
            });

            // Only navigate if viewmodel allows it
            sammy.before(/^((?!(windowsauth)).)*$/i, function (context) {
                if (context.path !== currentLocation) {
                    var currentView = activeViewObject();
                    if (currentView && currentView.vm && currentView.vm.canNavigateAway && !currentView.vm.canNavigateAway()) {
                        sammy.setLocation(currentLocation);
                        return false;
                    }
                }
                return true;
            });
        },

        run = function () {
            sammy.run("#/" + defaultView);
        },

        init = function () {
            loadTemplates().then(initRouting);
        };

    return {
        views: views,
        activeNavigationOptions: activeNavigationOptions,
        activeView: activeView,
        activeViewName: activeViewName,
        activeViewParams: activeViewParams,
        activeViewObject: activeViewObject,
        setLocation: setLocation,
        run: run,
        init: init
    };

})();