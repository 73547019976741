var vm = vm || {};

vm.ObjectNoteringar = function () {
    vm.Base.call(this);

    var self = this;
    this.alwaysEditing(true);

    this.inspectionHistory = dataservice.object.data().inspectionHistory;

    this.showOnlyLatest = ko.observable();
    this.showOnlyOverdue = ko.observable();
    this.showOnlyNotCorrected = ko.observable();
    this.showOnlyOverdue.subscribe(function (val) { if (val) self.showOnlyNotCorrected(false); });
    this.showOnlyNotCorrected.subscribe(function (val) { if (val) self.showOnlyOverdue(false); });

    this.inspectionHistoryFiltered = ko.pureComputed(function () {
        var result = [];
        var questionType = self.inspectionHistory.type;
        ko.utils.arrayForEach(self.inspectionHistory(), function (question) {
            var filteredAnswers = ko.utils.arrayFilter(question.answers(), function (answer) {
                return (answer.isFromLatestInspection() || !self.showOnlyLatest()) &&
                    ((!self.showOnlyOverdue() && !self.showOnlyNotCorrected()) ||
                    (answer.isOverdueActual() && self.showOnlyOverdue()) ||
                    (!answer.warningCorrected() && self.showOnlyNotCorrected()));
            });
            if (filteredAnswers.length) {
                var questionCopy = (new questionType)
                    .questionId(question.questionId())
                    .section(question.section())
                    .questionText(question.questionText())
                    .answers(filteredAnswers);
                result.push(questionCopy);
            }
        });
        return result;
    });
    this.hasAnswers = ko.pureComputed(function () {
        return ko.utils.arrayFirst(self.inspectionHistoryFiltered(), function (question) {
            return question.answers().length;
        });
    });
}

vm.ObjectNoteringar.prototype = Object.create(vm.Base.prototype);
vm.ObjectNoteringar.prototype.constructor = vm.ObjectNoteringar;

vm.ObjectNoteringar.prototype.openDocument = function (answer, index) {
    var locations = [];
    ko.utils.arrayForEach(answer.documents(), function (file, i) {
        if (utils.hasFileImageExtension(file.path())) {
            if (file.hasImageData()) {
                locations.push(file.imageData);
            } else {
                var token = sessionStorage.getItem("authorization_token");
                locations.push("api/answers/" + answer.id() + "/documents/" + file.id() + (token ? "?token=" + token : ""));
            }
        } else if (index > i) {
            index--;
        }
    });
    vm.imageViewer.show(locations, index);
}

vm.ObjectNoteringar.prototype.hrefForDocument = function (documentId, eventId) {
    var token = sessionStorage.getItem("authorization_token"),
        url = "api/answers/" + eventId() + "/documents/" + documentId() + "?size=94x44" + (token ? "&token=" + token : "");
    return url;
}

vm.ObjectNoteringar.prototype.submit = function () {
    vm.global.isBusy(true);
    return dataservice.object.updateInspectionHistory()
        .then(function () {
            vm.global.addNotification("Noteringarna har sparats.", "success");
            vm.global.isBusy(false);
        })
        .catch(function (e) {
            vm.global.addNotification("Det gick inte att spara, vänligen försök igen.", "error");
            vm.global.isBusy(false);
            throw e;
        });
}

vm.ObjectNoteringar.prototype.canNavigateAway = function () {
    var isDirty = this.isDirty();
    if (isDirty) {
        vm.global.addNotification("Du har gjort ändringar som du behöver spara eller ångra innan det går att byta vy.", "error");
    }
    return !isDirty;
}

vm.ObjectNoteringar.prototype.init = function (params) {
    vm.Base.prototype.init.call(this, params)
        .catch(function () {
            vm.global.addNotification("Det gick inte att hämta noteringarna för detta objekt.", "error");
        });
}

vm.objectNoteringar = new vm.ObjectNoteringar();
