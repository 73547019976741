var vm = vm || {};

vm.Inspection = function () {
    vm.Base.call(this);
    this.alwaysEditing(true);

    this.inspection = dataservice.object.data().inspection;
    this.object = dataservice.object.data().object;
    this.showConfirmRemoveInspection = ko.observable(); 

    this.hasInspectionTypes = ko.pureComputed(function () {
        return this.object().lsoInspection() || this.object().lbeInspection() || this.object().lsoLbeInspection();
    }, this);
    this.isInspectionTypeSelected = ko.pureComputed(function () {
        var inspection = this.inspection();
        if (!inspection) return false;
        return inspection.isLso22() || inspection.isLbe() || inspection.isLso24();
    }, this);
}

vm.Inspection.prototype = Object.create(vm.Base.prototype);
vm.Inspection.prototype.constructor = vm.Inspection;

vm.Inspection.prototype.submit = function () {
    vm.global.isBusy(true);
    return dataservice.object.updateInspection()
        .then(function () {
            vm.global.addNotification("Tillsynen har sparats.", "success");
            vm.global.isBusy(false);
        })
        .catch(function (e) {
            vm.global.addNotification("Det gick inte att spara, vänligen försök igen.", "error");
            vm.global.isBusy(false);
            throw e;
        });
}

vm.Inspection.prototype.toggleShowConfirmRemoveInspection = function () {
    this.showConfirmRemoveInspection(!this.showConfirmRemoveInspection());
}

vm.Inspection.prototype.removeInspection = function () {
    vm.global.isBusy(true);
    dataservice.object.removeInspection()
        .then(function () {
            vm.global.addNotification("Tillsynen har tagits bort.", "success");
            vm.global.isBusy(false);
        })
        .catch(function (e) {
            utils.logDebug(e);
            vm.global.addNotification("Det gick inte att ta bort tillsynen, vänligen försök igen.", "error");
            vm.global.isBusy(false);
        });

}

vm.Inspection.prototype.canNavigateAway = function () {
    var isDirty = this.isDirty();
    if (isDirty) {
        vm.global.addNotification("Du har gjort ändringar som du behöver spara eller ångra innan det går att byta vy.", "error");
    }
    return !isDirty;
}

vm.Inspection.prototype.init = function (params) {
    vm.Base.prototype.init.call(this, params)
        .catch(function (e) {
            utils.logDebug(e);
            vm.global.addNotification("Det gick inte att hämta tillsynen.", "error");
        });
}

vm.inspection = new vm.Inspection();
