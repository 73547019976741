var utils = utils || {};

utils.debug = window.location.search.indexOf("debug") !== -1 || config.debug;
utils.remotelogging = window.location.search.indexOf("remotelogging") !== -1 || config.remoteLogging;
utils.alertlogging = window.location.search.indexOf("alertlogging") !== -1;
utils.adminmode = window.location.search.indexOf("adminmode") !== -1;

utils.log = function () {
    var separator = " ";
    var now = new Date().toLocaleString("sv");
    var message = "";
    for (var i = 0; i < arguments.length; i++) {
        var arg = arguments[i];
        // Is param exception, log stack/message
        arg = arg instanceof Error ? arg.stack || arg.message : arg;
        var log;
        try {
            log = typeof arg === "object" ? JSON.stringify(arg) : arg;
        } catch (e) {
            log = arg;
        }
        message += log + (i < arguments.length - 1 ? separator : "");
    }

    // Filter password from login
    message = message.replace(/(\\"password\\":)\\".*?\\"/, "$1\\\"********\\\"");

    utils.originalConsoleLog(now + separator + message);

    if (utils.alertlogging)
        alert(now + separator + message);

    if (utils.remotelogging) {
        offlineCache.query("api/system/log", {
            method: "POST",
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify({
                date: now,
                message: message
            })
        });
    }
}

// Override console.log and console.dir so if used anywhere we can format the message
utils.originalConsoleLog = console.log.bind(console);
utils.originalConsoleDir = console.dir.bind(console);
console.log = utils.log;
console.dir = utils.log;

utils.logConsole = utils.originalConsoleLog;

utils.logDebug = function () {
    utils.debug && utils.log.apply(this, arguments);
}

// Log all uncatched errors
window.onerror = function () {
    utils.log.apply(this, arguments);
}

utils.query = function (url, options) {
    options = options || {};
    options.dataType = options.dataType || "json";
    options.method = options.method || "GET";
    if (options.method !== "GET") {
        options.contentType = typeof options.contentType === "boolean" ? options.contentType : options.contentType || "application/json";
    }

    utils.logDebug("AJAX Request" + (server.isOnline() ? "" : " (offline)") + ":", "url:", url, "options:", options);

    var request = offlineCache.query(url, options);

    if (utils.debug) {
        request.then(function (response) {
            utils.log("AJAX Result" + (server.isOnline() ? "" : " (offline)") + ":", "url:", url, "response:", response);
        });
    }

    request.catch(function (response) {
        utils.log("AJAX Error" + (server.isOnline() ? "" : " (offline)") + ":", "url:", url, "response:", response);
    });

    return request;
}

utils.ajaxCallbacks = [];
utils.ajax = function (url, options) {
    var ajax;
    var result = new Promise(function (resolve, reject) {
        var tokenType = sessionStorage.getItem("authorization_token_type"),
            token = sessionStorage.getItem("authorization_token");
        if (tokenType && token) {
            options = options || {};
            options.beforeSend = function (request) {
                request.setRequestHeader("Authorization", tokenType + " " + token);
            };
        }
        ajax = $.ajax(url, options);
        ajax.then(function (data, status, jqxhr) {
            resolve(data, status, jqxhr);
        }, function (jqxhr, status, error) {
            reject(jqxhr, status, error);
        });
        if (utils.ajaxCallbacks.length) {
            utils.ajaxCallbacks.forEach(function (cb) {
                ajax.then(function (data, status, jqxhr) { cb("success", data, status, jqxhr); }, function (jqxhr, status, error) { cb("error", jqxhr, status, error); });
            });
        }
    });
    result.abort = function () {
        ajax.abort();
    }
    return result;
}

utils.firstOrDefault = function (elements, filter, defaultValue) {
    var result = null;
    for (var i in elements) {
        if (elements.hasOwnProperty(i)) {
            var element = elements[i];
            if (filter(element, i) === true) {
                result = element;
                break;
            }
        }
    }
    return result || (typeof defaultValue !== "undefined" ? defaultValue : null);
}

// Today as string in YYYY-MM-DD format, updated every minute
utils.today = ko.observable();
utils.setToday = function () {
    var now = new Date;
    var month = now.getMonth() + 1;
    var day = now.getDate();
    var today = now.getFullYear() + "-" + (month <= 9 ? "0" + month : month) + "-" + (day <= 9 ? "0" + day : day);
    utils.today(today);
}
utils.setToday();
setInterval(function () {
    utils.setToday();
}, 60000);

utils.extend = $.extend;

utils.replaceRouteParams = function (route, params) {
    var r = route;

    for (var param in params) {
        if (params.hasOwnProperty(param)) {
            var value = ko.unwrap(params[param]);
            var paramRegExp = new RegExp(":" + param + "[\?]?");
            r = r.replace(paramRegExp, value);
        }
    }
    // Remove empty params
    r = r.replace(/:\w+[\?]?/, "");

    return r;
}

utils.hasFileImageExtension = function (file) {
    var extension = file.match(/\.?(\w+$)/);
    if (!extension || extension.length < 2) return false;
    return "png jpg jpeg gif bmp dib tif tiff".indexOf(extension[1].toLowerCase()) >= 0;
}

utils.scaleImage = function (data) {
    return new Promise(function (resolve) {
        var img = document.createElement("img");
        var canvas = document.createElement("canvas");
        img.onload = function () {
            var max = config.imageUpload.size;
            var imageWidth = img.width;
            var imageHeight = img.height;

            var isLandscape = imageWidth > imageHeight;
            var coeff = isLandscape ? max / imageWidth : max / imageHeight;
            var width = coeff > 1 ? imageWidth : imageWidth * coeff;
            var height = coeff > 1 ? imageHeight : imageHeight * coeff;

            canvas.setAttribute("width", width + "px");
            canvas.setAttribute("height", height + "px");
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            var newdata = canvas.toDataURL(config.imageUpload.fileType, config.imageUpload.quality);

            resolve(newdata);
        };
        img.src = data;
    });
}

utils.base64ImageToImgSrc = function (data, filename) {
    if (!data) return null;
    var prefix = "";
    if (data.slice(0, 10) !== "data:image") {
        var extension = filename.match(/\.?(\w+$)/);
        if (!extension || extension.length < 2) return null;
        extension = extension[1].toLowerCase();

        prefix = "data:image/" + (extension === "jpg" ? "jpeg" : extension) + ";base64,";
    }
    return prefix + data;
}

utils.removeHoverEffects = function() {
    var touch = "ontouchstart" in document.documentElement;
    if (touch) {
        for (var si in document.styleSheets) {
            if (document.styleSheets.hasOwnProperty(si)) {
                var styleSheet = document.styleSheets[si];
                if (!styleSheet.rules) continue;

                for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                    if (!styleSheet.rules[ri].selectorText) continue;

                    if (styleSheet.rules[ri].selectorText.match(":hover")) {
                        styleSheet.deleteRule(ri);
                    }
                }
            }
        }
    }
}

utils.noChosenForAndroid = function () {
    if (/Android/i.test(window.navigator.userAgent)) {
        $.fn.chosen = function () { return this; }
    }
}

utils.geolocation = (function () {

    var
        isSupported = typeof navigator.geolocation !== "undefined",

        get = function () {
            return new Promise(function (resolve, reject) {
                if (isSupported) {
                    var options = config.geolocationPositionOptions;

                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            resolve(position.coords);
                        },
                        function (positionError) {
                            reject(positionError);
                        },
                        options);

                } else {
                    reject("Geolocation not supported");
                }
            });
        };

    return {
        isSupported: isSupported,
        get: get
    };

})();
