var vm = vm || {};

vm.InspectionKontrollpunkter = function () {
    vm.Base.call(this);
    this.inspection = dataservice.object.data().inspection;
}

vm.InspectionKontrollpunkter.prototype = Object.create(vm.Base.prototype);
vm.InspectionKontrollpunkter.prototype.constructor = vm.InspectionKontrollpunkter;

vm.InspectionKontrollpunkter.prototype.createNotering = function (question) {
    var params = { id: this.currentId, question: question.id() };
    var route = utils.replaceRouteParams(config.tillsynNoteringRouteWithQuestion, params);
    navigation.setLocation("#/" + route);
}

vm.InspectionKontrollpunkter.prototype.canNavigateTo = function () {
    return this.inspection() && this.inspection().id() !== 0;
}

vm.InspectionKontrollpunkter.prototype.init = function (params) {
    return vm.Base.prototype.init.call(this, params)
        .catch(function (e) {
            vm.global.addNotification("Det gick inte att hämta tillsynen.", "error");
            throw e;
        });
}

vm.inspectionKontrollpunkter = new vm.InspectionKontrollpunkter();
