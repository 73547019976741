var dataservice = dataservice || {};

dataservice.Base = function () {
    this.data = ko.validatedObservable(undefined, { deep: true, observable: true, live: true });

    this.isValid = this.data.isValid;
    this.validationErrors = this.data.errors;
    if (utils.debug) {
        this.validationErrors.subscribe(function () {
            var errors = ko.validation.utils.propertiesWithValidationErrors(this.data);
            errors.length && utils.log(errors);
        }, this);
    }

    this.originalDataJs = null;
    this.originalDataJson = null;
    this.isDirtyFlag = ko.observable();
    this.isDirty = ko.computed(function () {
        if (this.isDirtyFlag() !== true) {
            this.isDirtyFlag(this.originalDataJson !== ko.toJSON(this.data));
        }
        return this.isDirtyFlag();
    }, this);
}

dataservice.Base.prototype.copyObjectIntoObservable = function (object, data) {
    var self = this;
    var unwrapped = ko.unwrap(data);
    var isArray = Array.isArray(object);

    if (isArray && object.length === 0 && ko.validation.utils.isObservableArray(data)) {
        data([]);
        return;
    }

    for (var property in object) {
        if (unwrapped.hasOwnProperty(property) || isArray) {
            var observable = isArray ? data : unwrapped[property];
            var obj = isArray ? object : object[property];
            if (ko.validation.utils.isObservableArray(observable)) {
                if (!observable.type) {
                    throw new Error("Observable Array does not have type set. Use extend({type: TYPE)}");
                }
                if (obj) {
                    ko.utils.arrayForEach(obj, function (item, index) {
                        var observableItem = observable()[index];
                        if (observableItem) {
                            if (observable.type === String || observable.type === Number || observable.type === Boolean)
                                observableItem = item;
                            else
                                self.copyObjectIntoObservable(item, observableItem);
                        } else {
                            var element = new observable.type;
                            if (observable.type === String || observable.type === Number || observable.type === Boolean)
                                element = item;
                            else
                                element = self.copyObjectIntoObservable(item, element);
                            observable.push(element);
                        }
                    });
                    for (var i = obj.length; i < observable().length;) {
                        observable.pop();
                    }
                } else {
                    observable(null);
                }
            } else if (observable.type) {
                if (observable()) {
                    self.copyObjectIntoObservable(obj, observable);
                } else if (obj) {
                    var value = new observable.type;
                    value = self.copyObjectIntoObservable(obj, value);
                    observable(value);
                }
            } else if (!ko.isComputed(observable)) {
                observable(obj);
            }
        }
    }
    return data;
}

dataservice.Base.prototype.commit = function () {
    this.originalDataJson = ko.toJSON(this.data);
    this.originalDataJs = ko.toJS(this.data);
    this.isDirtyFlag(false);
}

dataservice.Base.prototype.rollback = function () {
    this.copyObjectIntoObservable(this.originalDataJs, this.data);
    this.isDirtyFlag(false);
}
