var vm = vm || {};

vm.InspectionNotering = function () {
    vm.Base.call(this);
    this.alwaysEditing(true);

    this.inspection = dataservice.object.data().inspection;
    this.inspectionNewAnswer = dataservice.object.data().inspectionNewAnswer;

    this.questionId = 0;
    this.selectedChecklist = ko.observable();
    this.selectedSection = ko.observable();
    this.selectedQuestion = ko.observable();

    this.selectedChecklist.subscribe(function () {
        this.selectedSection(null);
    }, this);
    this.selectedSection.subscribe(function () {
        this.selectedQuestion(null);
    }, this);
    this.selectedQuestion.subscribe(function (question) {
        dataservice.object.newInspectionNewAnswer(question);
        dataservice.object.commit();
    }, this);
}

vm.InspectionNotering.prototype = Object.create(vm.Base.prototype);
vm.InspectionNotering.prototype.constructor = vm.InspectionNotering;

vm.InspectionNotering.prototype.getQuestion = function (questionId) {
    var checklist, section, question = null;
    var checklists = this.inspection().checkLists();
    for (var c in checklists) {
        var sections = checklists[c].sections();
        for (var s in sections) {
            var questions = sections[s].questions();
            for (var q in questions) {
                if (questions[q].id() == questionId) {
                    question = questions[q];
                    break;
                }
            }
            if (question) {
                section = sections[s];
                break;
            }
        }
        if (question) {
            checklist = checklists[c];
            break;
        }
    }

    return { checklist: checklist, section: section, question: question };
}

vm.InspectionNotering.prototype.selectQuestion = function (questionId) {
    var question = this.getQuestion(questionId);
    this.selectedChecklist(question.checklist);
    this.selectedSection(question.section);
    this.selectedQuestion(question.question);
}

vm.InspectionNotering.prototype.addFile = function (element, newAnswer, event) {
    if (!utils.hasFileImageExtension(element.value)) {
        vm.global.addNotification("Du kan bara lägga till bilder.", "error");
        return;
    }

    new Promise(function (resolve, reject) {
            var file = event.target.files[0];
            try {
                // Try to read the file using createObjectURL
                var createResult = window.URL.createObjectURL(file);
                resolve(createResult);
            } catch (e) {
                try {
                    // Fallback if createObjectURL is not supported
                    var fileReader = new FileReader();
                    fileReader.onload = function (ev) {
                        var readerResult = ev.target.result;
                        resolve(readerResult);
                    };
                    fileReader.readAsDataURL(file);
                } catch (e) {
                    // Cannot read file at all, fail
                    reject();
                }
            }
        })
        .then(function (imageData) {
            // Add file to answer
            var newFile = new (newAnswer.documents.type);
            newFile.path(element.value);
            element.value = null;
            // Scale image
            utils.scaleImage(imageData)
                .then(function (scaledImageData) {
                    newFile.imageData(scaledImageData);
                    newAnswer.documents.push(newFile);
                });
        })
        .catch(function () {
            element.value = null;
            vm.global.addNotification("Det gick inte att läsa bilden, vänligen försök igen.", "error");
        });
}

vm.InspectionNotering.prototype.removeNewFile = function (file) {
    this.inspectionNewAnswer().documents.remove(file);
}

vm.InspectionNotering.prototype.openDocument = function (index) {
    var locations = [];
    ko.utils.arrayForEach(this.inspectionNewAnswer().documents(), function (file, i) {
        if (utils.hasFileImageExtension(file.path()))
            locations.push(file.imageData());
        else if (index > i)
            index--;
    });
    vm.imageViewer.show(locations, index);
}

vm.InspectionNotering.prototype.submit = function () {
    var self = this;
    vm.global.isBusy(true);

    return dataservice.object.updateInspection()
        .then(function () {
            vm.global.addNotification("Noteringen har sparats.", "success");
            vm.global.isBusy(false);

            // Reset notering form
            self.selectedChecklist(null);

            // Redirect to komplettering if coming from there
            if (self.questionId) {
                self.questionId = 0;
                var route = utils.replaceRouteParams(config.tillsynKontrollpunkterRoute, { id: self.currentId });
                navigation.setLocation("#/" + route);
            }
        })
        .catch(function (e) {
            vm.global.addNotification("Det gick inte att spara, vänligen försök igen.", "error");
            vm.global.isBusy(false);
            throw e;
        });
}

vm.InspectionNotering.prototype.canNavigateAway = function () {
    var isDirty = this.isDirty();
    if (isDirty) {
        vm.global.addNotification("Du har gjort ändringar som du behöver spara eller ångra innan det går att byta vy.", "error");
    }
    return !isDirty;
}

vm.InspectionNotering.prototype.canNavigateTo = function () {
    return this.inspection() && this.inspection().id() !== 0;
}

vm.InspectionNotering.prototype.init = function (params) {
    var self = this;
    return vm.Base.prototype.init.call(this, params)
        .then(function () {
            // params.question holds question id when user selected a question in kontrollpunkter view
            if (self.questionId != params.question) {
                self.questionId = params.question;
                self.questionId && self.selectQuestion(self.questionId);
            }
        })
        .catch(function (e) {
            vm.global.addNotification("Det gick inte att hämta tillsynen.", "error");
            throw e;
        });
}

vm.inspectionNotering = new vm.InspectionNotering();
