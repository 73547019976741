var dataservice = dataservice || {};

dataservice.Object = function () {
    dataservice.Base.call(this);
    // Params
    this.currentId = ko.observable(0);

    // Object data that we should be able to rollback and/or check dirty state of
    this.data({
        object: ko.observable().extend({ type: model.Object }),
        inspection: ko.observable().extend({ type: model.Inspection }),
        inspectionContacts: ko.observableArray().extend({ type: model.InspectionContact }),
        inspectionHistory: ko.observableArray().extend({ type: model.InspectionHistoryQuestion }),
        inspectionNewAnswer: ko.observable().extend({ type: model.InspectionAnswer })
    });

    // Other object data
    this.inspectionCases = ko.observableArray().extend({ type: model.Case });
    this.safetyReports = ko.observable().extend({ type: model.SafetyReports });
    this.inspectionGroups = ko.observableArray().extend({ type: model.InspectionGroup });
    this.organisationRoles = ko.observableArray().extend({ type: model.OrganisationRole });
    this.documentArchive = ko.observableArray().extend({ type: model.CoreDocumentCategory });
    this.caseWorkers = ko.observableArray().extend({ type: model.CaseWorker });
    this.objectContactsAsInspectionContacts = ko.pureComputed(function () {
        var contacts = [];
        ko.utils.arrayForEach(this.data().object().organisations(), function (organisation) {
            ko.utils.arrayForEach(organisation.contacts(), function (contact) {
                var insp = new model.InspectionContact;
                insp.contactId(contact.id())
                    .name(contact.name())
                    .organisation(organisation.name())
                    .phone(contact.phoneWork())
                    .email(contact.email());
                contacts.push(insp);
            });
        });
        return contacts;
    }, this);
}

dataservice.Object.prototype = Object.create(dataservice.Base.prototype);
dataservice.Object.prototype.constructor = dataservice.Object;

utils.extend(dataservice.Object.prototype, function () {
    var
        // Get functions
        getData = function (getApiFunc, id, data, resolveOnNotFound) {
            var self = this;

            var isArray = ko.validation.utils.isObservableArray(data);
            if (isArray)
                data.removeAll();

            return getApiFunc(ko.unwrap(id))
                .then(function (response) {
                    if (!isArray)// && !data())
                        data(new (data.type));
                    self.copyObjectIntoObservable(response, data);
                    return response;
                })
                .catch(function (response) {
                    if (!isArray)
                        data(undefined);
                    if (resolveOnNotFound && response && response.status === 404)
                        return Promise.resolve();
                    throw response;
                });
        },
        getObject = function () {
            return getData.call(this, dataapi.object.getObject, this.currentId, this.data().object);
        },
        getInspectionCases = function () {
            return getData.call(this, dataapi.object.getInspectionCases, this.currentId, this.inspectionCases);
        },
        getInspectionHistory = function () {
            return getData.call(this, dataapi.object.getInspectionHistory, this.currentId, this.data().inspectionHistory, true);
        },
        getSafetyReports = function () {
            return getData.call(this, dataapi.object.getSafetyReports, this.currentId, this.safetyReports, true);
        },
        getInspectionGroups = function () {
            return getData.call(this, dataapi.object.getInspectionGroups, null, this.inspectionGroups);
        },
        getOrganisationRoles = function () {
            return getData.call(this, dataapi.object.getOrganisationRoles, null, this.organisationRoles);
        },
        getInspection = function () {
            var self = this;
            return getData.call(this, dataapi.object.getInspection, this.currentId, this.data().inspection, true)
                .then(function () {
                    if (!self.data().inspection()) {
                        newEmptyInspection.call(self);
                    }
                });
        },
        getInspectionContacts = function () {
            return getData.call(this, dataapi.object.getInspectionContacts, this.currentId, this.data().inspectionContacts, true);
        },
        getDocumentArchive = function () {
            return getData.call(this, dataapi.object.getDocumentArchive, null, this.documentArchive);
        },
        getCaseWorkers = function () {
            return getData.call(this, dataapi.object.getCaseWorkers, null, this.caseWorkers);
        },

        // Create/update functions
        updateData = function (updateApiFunc) {
            var self = this;
            var params = Array.prototype.slice.call(arguments, 1);
            var unwrapped = ko.utils.arrayMap(params, function (param) {
                return ko.toJS(param);
            });

            return updateApiFunc.apply(this, unwrapped)
                .then(function (result) {
                    if (params.length !== 0) {
                        self.copyObjectIntoObservable(result, params[params.length - 1]);
                        self.commit();
                    }
                });
        },
        updateObject = function () {
            return updateData.call(this, dataapi.object.updateObject, this.currentId, this.data().object);
        },
        updateInspectionHistory = function () {
            // Update all warningCorrected and isOverdue
            ko.utils.arrayForEach(this.data().inspectionHistory(), function (question) {
                ko.utils.arrayForEach(question.answers(), function (answer) {
                    answer.warningCorrected(answer.correctionDate() !== null);
                    answer.isOverdue(!answer.warningCorrected() && answer.actionDeadline() < utils.today());
                });
            });

            return updateData.call(this, dataapi.object.updateInspectionHistory, this.currentId, this.data().inspectionHistory);
        },
        updateInspection = function () {
            var inspection = this.data().inspection();
            var inspectionNewAnswer = this.data().inspectionNewAnswer();
            if (inspectionNewAnswer && inspectionNewAnswer.answer())
                this.data().inspectionNewAnswer(undefined);
            
            // Update answer warning flag and add new answer if any
            ko.utils.arrayForEach(inspection.checkLists(), function (checklist) {
                ko.utils.arrayForEach(checklist.sections(), function (section) {
                    ko.utils.arrayForEach(section.questions(), function (question) {
                        if (inspectionNewAnswer && inspectionNewAnswer.answer() && inspectionNewAnswer.questionId() === question.id()) {
                            inspectionNewAnswer.inspectionId(inspection.id());
                            question.answers.push(inspectionNewAnswer);
                        }
                        ko.utils.arrayForEach(question.answers(), function (answer) {
                            var alternative = ko.utils.arrayFirst(question.alternatives(), function (alt) {
                                return alt.answer() === answer.answer();
                            });
                            if (alternative)
                                answer.warning(alternative.warning());
                        });
                    });
                });
            });

            if (inspection && inspection.id() === 0)
                // New inspection, create
                return updateData.call(this, dataapi.object.createInspection, this.currentId, this.data().inspection);
            // Existing inspection, update
            return updateData.call(this, dataapi.object.updateInspection, this.currentId, this.data().inspection);
        },
        closeInspection = function () {
            // Close inspection by setting its date
            this.data().inspection().date(utils.today());
            return updateInspection.call(this);
        },
        removeInspection = function () {
            var self = this;
            return dataapi.object.removeInspection(this.currentId())
                .then(function () {
                    clearInspection.call(self);
                    return getInspectionContacts.call(self)
                        .then(function () {
                            self.commit();
                        });
                });
        },
        updateInspectionContacts = function () {
            var inspection = this.data().inspection();
            ko.utils.arrayForEach(this.data().inspectionContacts(), function (contact) { contact.inspectionId(inspection.id()); });
            return updateData.call(this, dataapi.object.updateInspectionContacts, this.currentId, this.data().inspectionContacts);
        },
        newEmptyInspection = function () {
            this.data().inspection(new (this.data().inspection.type));
            this.data().inspection().memo(new (this.data().inspection().memo.type));
            this.data().inspection().coreObjectId(this.currentId());
            this.data().inspectionContacts.removeAll();
        },
        clearInspection = function () {
            var i, prop,
                inspection = this.data().inspection(),
                memo = inspection.memo();
            for (i in inspection) {
                if (i !== "memo") {
                    prop = inspection[i];
                    if (ko.isWritableObservable(prop))
                        prop(prop.defaultValue);
                }
            }
            for (i in memo) {
                prop = memo[i];
                if (ko.isWritableObservable(prop))
                    prop(prop.defaultValue);
            }
            inspection.coreObjectId(this.currentId());
            inspection.checkLists([]);
        },
        newInspectionNewAnswer = function (forQuestion) {
            if (!forQuestion) return;
            this.data().inspectionNewAnswer(new (this.data().inspectionNewAnswer.type));
            this.data().inspectionNewAnswer().questionId(forQuestion.id());
            this.data().inspectionNewAnswer().memo(new (this.data().inspectionNewAnswer().memo.type));
            this.data().inspectionNewAnswer().questionText(forQuestion.questionText());
        },
        setInspectionCaseWorkerId = function (id) {
            // Only set once
            if (this.data().inspection().caseWorkerId()) return;

            var caseWorkerExists = ko.utils.arrayFirst(this.caseWorkers(), function (cw) {
                return cw.id() === id;
            });
            if (caseWorkerExists) {
                this.data().inspection().caseWorkerId(id);
                this.commit();
            }
        },

        // Other
        refresh = function () {
            var objectId = this.currentId();
            this.currentId(0);
            return this.init(objectId);
        },
        init = function (objectId, beforeInit) {
            if (!objectId) return Promise.reject();

            var self = this;
            if (this.currentId() !== objectId) {
                this.currentId(objectId);

                if (typeof beforeInit === "function")
                    beforeInit();

                var getObjectAll = [
                    getObject,
                    getInspectionCases,
                    getInspectionHistory,
                    getSafetyReports,
                    getInspectionGroups,
                    getOrganisationRoles,
                    getInspection,
                    getInspectionContacts,
                    getDocumentArchive,
                    getCaseWorkers
                ];

                var result = getObjectAll[0].call(this);
                for (var i = 1; i < getObjectAll.length; i++) {
                    result = result.then(getObjectAll[i].bind(this));
                }

                result = result.then(function () {
                    self.commit();
                    return true;
                }, function (e) {
                    self.currentId(0);
                    self.commit();
                    throw e;
                });

                return result;
            } else {
                return Promise.resolve(false);
            }
        };

    return {
        updateObject: updateObject,
        updateInspectionHistory: updateInspectionHistory,
        updateInspection: updateInspection,
        closeInspection: closeInspection,
        removeInspection: removeInspection,
        newEmptyInspection: newEmptyInspection,
        updateInspectionContacts: updateInspectionContacts,
        newInspectionNewAnswer: newInspectionNewAnswer,
        setInspectionCaseWorkerId: setInspectionCaseWorkerId,

        refresh: refresh,
        init: init
    };
}());

dataservice.object = new dataservice.Object();
