var vm = vm || {};

vm.Search = function () {
    this.ready = ko.observable();
    this.isBusySearching = ko.observable();
    this.isPositionSearch = ko.observable();
    this.isPositionSearchEnabled = utils.geolocation.isSupported;
    this.useButton = config.search.useButton;
    this.onlineSubscription = null;

    this.caseWorkers = dataservice.search.caseWorkers;
    this.selectedCaseWorkers = dataservice.search.data().selectedCaseWorkers.extend({ title: "Handläggare" });
    this.searchText = dataservice.search.data().searchText.extend({ title: "Objekt", rateLimit: { timeout: config.search.beginSearchTimeout, method: "notifyWhenChangesStop" }, minLength: { params: config.search.minChars, message: "Skriv minst {0} tecken för att söka" } });
    this.searchResults = dataservice.search.searchResults;

    // Perform search if search parameters change if no search button
    if (!this.useButton) {
        this.searchText.subscribe(this.searchUsingText, this);
        this.selectedCaseWorkers.subscribe(this.searchUsingText, this);
    }
}

vm.Search.prototype.removeObjectFromCache = function (object) {
    var self = this;
    offlineCache.removeObjectFromCache(object.objectId())
        .then(function () {
            self.searchUsingText();
        });
}

vm.Search.prototype.clearCache = function () {
    var self = this;
    offlineCache.clearCache()
        .then(function () {
            vm.global.addNotification("Alla objekt har raderats.", "success");
            self.searchUsingText();
        });
}

vm.Search.prototype.isFromCache = function (object) {
    return object.isFromCache() && vm.global.isOnline();
}

vm.Search.prototype.searchUsingText = function () {
    var self = this;
    self.isPositionSearch(false);
    self.isBusySearching(false);

    if (self.searchText() && self.searchText().length >= config.search.minChars && vm.global.isOnline()) {
        self.isBusySearching(true);
        dataservice.search.searchUsingText()
            .then(function () {
                self.isBusySearching(false);
            })
            .catch(function (response) {
                if (response.status !== 0) {
                    self.isBusySearching(false);
                    vm.global.addNotification("Det gick inte att söka, vänligen försök igen.", "error");
                }
            });
    } else {
        dataservice.search.useCachedObjectsForSearchResult();
    }
}

vm.Search.prototype.searchUsingPosition = function () {
    var self = this;
    self.isBusySearching(true);
    dataservice.search.searchUsingPosition()
        .then(function () {
            self.isPositionSearch(true);
            self.isBusySearching(false);
        })
        .catch(function (response) {
            if (response.status !== 0) {
                self.isBusySearching(false);
                vm.global.addNotification("Det gick inte att söka genom att använda din position.", "error");
            }
        });
},

vm.Search.prototype.openObject = function (object) {
    var params = { objectId: object.objectId() };
    var route = utils.replaceRouteParams(config.searchTargetRoute, params);
    navigation.setLocation("#/" + route);
}

vm.Search.prototype.rollback = function () {
    dataservice.search.abortSearch();
    this.searchText("");
    this.selectedCaseWorkers.removeAll();
}

vm.Search.prototype.refresh = function () {
    dataservice.search.abortSearch();
    this.searchText("");
    this.selectedCaseWorkers.removeAll();

    this.initialized = false;
    this.init();
}

vm.Search.prototype.init = function () {
    // Always reload object even if loaded one is chosen
    dataservice.object.currentId(0);
    
    if (this.initialized) {
        this.searchUsingText();
        return;
    }

    var self = this;
    self.ready(false);
    vm.global.isBusy(true);

    // Show cached objects if we go offline
    if (!self.onlineSubscription) {
        self.onlineSubscription = vm.global.isOnline.subscribe(function (online) {
            if (!online || !this.useButton)
                this.searchUsingText();
        }, self);
    }

    // Get all case workers
    dataservice.search.getCaseWorkers()
        .then(function () {
            tekis.components.init()
                .then(function () {
                    // Add logged in user as selected case worker
                    ko.utils.arrayForEach(self.caseWorkers(), function (cw) {
                        if (cw.isCurrentUser()) {
                            self.selectedCaseWorkers([cw.id()]);
                            return;
                        }
                    });
                    self.initialized = true;
                    self.ready(true);
                });
            vm.global.isBusy(false);
            self.searchUsingText();
        })
        .catch(function (response) {
            if (response && (response.status === 404 || response.status === 500)) {
                vm.global.addNotification("Det gick inte att hämta handläggare.", "error");
            }
            tekis.components.init()
                .then(function () {
                    self.ready(true);
                });
            vm.global.isBusy(false);
            self.searchUsingText();
        });
}

vm.search = new vm.Search();