var model = model || {};

model.User = function () {
    var self = this;

    self.username = ko.observable();
    self.password = ko.observable();
    self.displayName = ko.observable();
    self.currentCaseWorkerId = ko.observable();
    self.isLoggedIn = ko.observable(false);
    self.isAdmin = ko.observable(false);
};
