var vm = vm || {};

vm.global = (function () {

    var
        navigationOptions = navigation.views,
        activeNavigationOptions = navigation.activeNavigationOptions,
        activeView = navigation.activeView,
        activeViewName = navigation.activeViewName,
        activeViewParams = navigation.activeViewParams,

        timerId = 0,
        notification = ko.observable(),
        dismissNotification = function () {
            notification(null);
            clearTimeout(timerId);
            timerId = 0;
        },
        addNotification = function (message, type) {
            type = type || "info";
            utils.logDebug("Notification: " + type + ": " + message);
            notification({ message: message, type: type });
            if (timerId)
                clearTimeout(timerId);
            timerId = setTimeout(dismissNotification, config.notificationTimeout);
        },

        isBusy = ko.observable(false).extend({ rateLimit: 100 }),

        forceOffline = server.forceOffline,
        isOnline = server.isOnline,
        workOffline = function () {
            forceOffline(true);
        },
        workOnline = function () {
            forceOffline(false);
        },

        activeViewIsDirty = ko.pureComputed(function () {
            var currentView = navigation.activeViewObject();
            if (currentView && currentView.vm && currentView.vm.isDirty)
                return currentView.vm.isDirty();
            return false;
        }),
        canCurrentViewRefresh = ko.pureComputed(function () {
            var currentView = navigation.activeViewObject();
            return isOnline() && currentView && currentView.vm && currentView.vm.refresh;
        }),
        refreshCurrentView = function () {
            var currentView = navigation.activeViewObject();
            if (currentView && currentView.vm && currentView.vm.refresh && (!currentView.vm.isDirty || !currentView.vm.isDirty()))
                currentView.vm.refresh();
            return false;
        };
    
    isOnline.subscribe(function (online) {
        if (!online) return;
        // Refresh the current view when app goes online, this will cause most views to load their data if they don't have any cached
        var currentView = navigation.activeViewObject();
        if (currentView && currentView.vm && currentView.vm.init)
            currentView.vm.init(activeViewParams());
    });

    return {
        navigationOptions: navigationOptions,
        activeNavigationOptions: activeNavigationOptions,
        activeView: activeView,
        activeViewName: activeViewName,
        activeViewParams: activeViewParams,

        notification: notification,
        addNotification: addNotification,
        dismissNotification: dismissNotification,

        isBusy: isBusy,

        forceOffline: forceOffline,
        isOnline: isOnline,
        workOffline: workOffline,
        workOnline: workOnline,
        isInAdminMode: utils.adminmode,

        activeViewIsDirty: activeViewIsDirty,
        canCurrentViewRefresh: canCurrentViewRefresh,
        refreshCurrentView: refreshCurrentView
    };

})();
