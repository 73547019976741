var model = model || {};

model.CoreDocument = function () {
    var self = this;

    self.id = ko.observable().extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.categoryId = ko.observable().extend({ integer: true });
    self.name = ko.observable().extend({ title: "Namn" });
    self.type = ko.observable().extend({ title: "Typ" });
    self.uri = ko.observable().extend({ title: "Sökväg" });
    self.fileId = ko.observable();

    // Derived properties
    self.fileExtension = ko.observable().extend({ title: "Filtyp" });
    self.metaData = ko.observableArray().extend({ type: model.CoreDocumentMetaData });

    // Computed
    self.fileType = ko.pureComputed(function () {
        if (self.fileExtension())
            return self.fileExtension();

        // Return protocol for links
        return self.uri().substring(0, self.uri().indexOf("://"));
    });
};

model.CoreDocumentCategory = function () {
    var self = this;

    self.id = ko.observable().extend({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.name = ko.observable().extend({ title: "Namn" });

    // Related data
    self.documents = ko.observableArray().extend({ title: "Dokument", type: model.CoreDocument });
};

model.CoreDocumentMetaData = function () {
    var self = this;

    self.key = ko.observable();
    self.value = ko.observable();
}