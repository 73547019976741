var vm = vm || {};

vm.InspectionSammanstallning = function () {
    vm.Base.call(this);
    this.alwaysEditing(true);

    this.inspection = dataservice.object.data().inspection;
    this.caseWorkers = dataservice.object.caseWorkers;

    this.filterWarningAnswersOnly = ko.observable();
    this.showConfirmRemoveAnswer = ko.observable();
    this.confirmForAnswer = ko.observable();
    this.showConfirmCloseInspection = ko.observable();
}

vm.InspectionSammanstallning.prototype = Object.create(vm.Base.prototype);
vm.InspectionSammanstallning.prototype.constructor = vm.InspectionSammanstallning;

vm.InspectionSammanstallning.prototype.getQuestionById = function (questionId) {
    var checklists = this.inspection().checkLists();
    for (var c in checklists) {
        var sections = checklists[c].sections();
        for (var s in sections) {
            var questions = sections[s].questions();
            for (var q in questions) {
                if (questions[q].id() == questionId) {
                    return questions[q];
                }
            }
        }
    }
    return null;
}

vm.InspectionSammanstallning.prototype.toggleShowConfirmRemoveAnswer = function (answer) {
    this.showConfirmRemoveAnswer(!this.showConfirmRemoveAnswer());
    this.confirmForAnswer(answer instanceof model.InspectionAnswer ? answer : null);
}

vm.InspectionSammanstallning.prototype.removeAnswer = function () {
    var question = this.getQuestionById(this.confirmForAnswer().questionId());
    if (question) {
        question.answers.remove(this.confirmForAnswer());
    }
    this.confirmForAnswer(null);
}

vm.InspectionSammanstallning.prototype.addFile = function (element, answer, event) {
    if (!utils.hasFileImageExtension(element.value)) {
        vm.global.addNotification("Du kan bara lägga till bilder.", "error");
        return;
    }

    new Promise(function (resolve, reject) {
            var file = event.target.files[0];
            try {
                // Try to read the file using createObjectURL
                var createResult = window.URL.createObjectURL(file);
                resolve(createResult);
            } catch (e) {
                try {
                    // Fallback if createObjectURL is not supported
                    var fileReader = new FileReader();
                    fileReader.onload = function (ev) {
                        var readerResult = ev.target.result;
                        resolve(readerResult);
                    };
                    fileReader.readAsDataURL(file);
                } catch (e) {
                    // Cannot read file at all, fail
                    reject();
                }
            }
        })
        .then(function (imageData) {
            // Add file to answer
            var newFile = new (answer.documents.type);
            newFile.path(element.value);
            element.value = null;
            // Scale image
            utils.scaleImage(imageData)
                .then(function (scaledImageData) {
                    newFile.imageData(scaledImageData);
                    answer.documents.push(newFile);
                });
        })
        .catch(function () {
            element.value = null;
            vm.global.addNotification("Det gick inte att läsa bilden, vänligen försök igen.", "error");
        });
}

vm.InspectionSammanstallning.prototype.removeExistingFile = function (file) {
    this.documents.remove(file);
}

vm.InspectionSammanstallning.prototype.toggleShowConfirmCloseInspection = function () {
    this.showConfirmCloseInspection(!this.showConfirmCloseInspection());
}

vm.InspectionSammanstallning.prototype.closeInspection = function () {
    vm.global.isBusy(true);
    dataservice.object.closeInspection()
        .then(function () {
            vm.global.addNotification("Tillsynen har avslutats.", "success");
            vm.global.isBusy(false);
            // Show search
            var route = utils.replaceRouteParams(config.tillsynClosedRoute);
            navigation.setLocation("#/" + route);
        })
        .catch(function () {
            vm.global.addNotification("Det gick inte att avsluta tillsynen, vänligen försök igen.", "error");
            vm.global.isBusy(false);
        });
}

vm.InspectionSammanstallning.prototype.openDocument = function (answer, index) {
    var locations = [];
    ko.utils.arrayForEach(answer.documents(), function (file, i) {
        if (utils.hasFileImageExtension(file.path())) {
            if (file.hasImageData()) {
                locations.push(file.imageData);
            } else {
                var token = sessionStorage.getItem("authorization_token");
                locations.push("api/answers/" + answer.id() + "/documents/" + file.id() + (token ? "?token=" + token : ""));
            }
        } else if (index > i) {
            index--;
        }
    });
    vm.imageViewer.show(locations, index);
}

vm.InspectionSammanstallning.prototype.hrefForDocument = function (documentId, eventId) {
    var token = sessionStorage.getItem("authorization_token"),
        url = "api/answers/" + eventId() + "/documents/" + documentId() + "?size=94x44" + (token ? "&token=" + token : "");
    return url;
}

vm.InspectionSammanstallning.prototype.submit = function () {
    vm.global.isBusy(true);
    return dataservice.object.updateInspection()
        .then(function () {
            vm.global.addNotification("Tillsynen har sparats.", "success");
            vm.global.isBusy(false);
        })
        .catch(function (e) {
            vm.global.addNotification("Det gick inte att spara, vänligen försök igen.", "error");
            vm.global.isBusy(false);
            throw e;
        });
}

vm.InspectionSammanstallning.prototype.cancelCloseInspection = function () {
    this.rollback();
    return true;
}

vm.InspectionSammanstallning.prototype.canNavigateAway = function () {
    var isDirty = this.isDirty();
    if (isDirty) {
        vm.global.addNotification("Du har gjort ändringar som du behöver spara eller ångra innan det går att byta vy.", "error");
    }
    return !isDirty;
}

vm.InspectionSammanstallning.prototype.canNavigateTo = function () {
    return this.inspection() && this.inspection().id() !== 0;
}

vm.InspectionSammanstallning.prototype.init = function (params) {
    return vm.Base.prototype.init.call(this, params)
        .then(function () {
            var loginInfo = dataservice.login.loginInfo;
            if (typeof loginInfo === "function")
                dataservice.object.setInspectionCaseWorkerId(loginInfo().currentCaseWorkerId());
        })
        .catch(function (e) {
            vm.global.addNotification("Det gick inte att hämta tillsynen.", "error");
            throw e;
        });
}

vm.inspectionSammanstallning = new vm.InspectionSammanstallning();
