var dataservice = dataservice || {};

dataservice.Login = function () {
    dataservice.Base.call(this);
    this.loginInfo = ko.observable().extend({ type: model.User });
    this.loginInfo(new (this.loginInfo.type));

    var self = this;
    utils.ajaxCallbacks.push(function (status, xhr) {
        if (status === "error" && xhr && xhr.status === 401) {
            self.loginInfo().isLoggedIn(false);
            self.loginInfo().displayName("");
            self.loginInfo().isAdmin(false);
        }
    });
}

dataservice.Login.prototype = Object.create(dataservice.Base.prototype);
dataservice.Login.prototype.constructor = dataservice.Login;

utils.extend(dataservice.Login.prototype, function () {
    var
        getTokenFromWindowsAuthCookie = function () {
            var authCookie = document.cookie.split(";").map(function (c) { return c.trim(); }).find(function (c) { return c.substr(0, c.indexOf("=")) === config.windowsAuthCookieName; });
            if (authCookie) {
                document.cookie = config.windowsAuthCookieName + "=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/";

                var authValues = authCookie.substr(authCookie.indexOf("=") + 1).split("&").map(function (kvp) { return kvp.split("="); });

                var access_token = authValues.find(function (x) { return x[0] === "access_token"; }),
                    expires_in = authValues.find(function (x) { return x[0] === "expires_in"; }),
                    token_type = authValues.find(function (x) { return x[0] === "token_type"; });

                if (access_token && expires_in && token_type) {
                    sessionStorage.setItem("authorization_token_type", token_type[1]);
                    sessionStorage.setItem("authorization_token", access_token[1]);
                }
            }
        },
        logoutLocally = function () {
            this.loginInfo().isLoggedIn(false);
            this.loginInfo().displayName("");
            this.loginInfo().isAdmin(false);
        },
        getLoginInfo = function () {
            var self = this;
            getTokenFromWindowsAuthCookie();
            return dataapi.login.getLoginInfo()
                .then(function (result) {
                    self.copyObjectIntoObservable(result, self.loginInfo);
                })
                .catch(logoutLocally.bind(self));
        },
        login = function () {
            var self = this;
            var credentials = { username: this.loginInfo().username(), password: this.loginInfo().password() };
            return dataapi.login.login(credentials)
                .then(function (result) {
                    self.loginInfo().password(undefined);
                    sessionStorage.setItem("authorization_token_type", result.token_type);
                    sessionStorage.setItem("authorization_token", result.access_token);
                    return getLoginInfo.call(self);
                })
                .catch(function (e) {
                    self.loginInfo().password(undefined);
                    logoutLocally.call(self);
                    throw e;
                });
        },
        logout = function () {
            sessionStorage.removeItem("authorization_token_type");
            sessionStorage.removeItem("authorization_token");
            logoutLocally.call(this);
        };

    return {
        getLoginInfo: getLoginInfo,
        login: login,
        logout: logout
    };
}());

dataservice.login = new dataservice.Login();
