var vm = vm || {};

vm.login = (function () {

    var user = dataservice.login.loginInfo,
        hasPasswordFocus = ko.observable(),
        loginError = ko.observable(),
        showWarningOffline = ko.observable(),

        login = function () {
            loginError(false);
            vm.global.isBusy(true);
            dataservice.login.login()
                .then(function () {
                    vm.global.isBusy(false);
                    hasPasswordFocus(user().username());
                })
                .catch(function () {
                    loginError(true);
                    vm.global.isBusy(false);
                    hasPasswordFocus(user().username());
                });
        },

        logout = function () {
            dataservice.login.logout();
        },

        toggleShowWarningOffline = function () {
            showWarningOffline(!showWarningOffline());
        },

        logoutWithCheck = function () {
            vm.global.isOnline() ? logout() : toggleShowWarningOffline();
        },

        init = function () {
            var self = this;

            // Clear login errors and check if we're still logged in when going online/offline
            vm.global.isOnline.subscribe(function () {
                loginError(false);
                dataservice.login.getLoginInfo();
            });

            return new Promise(function (resolve, reject) {
                vm.global.isBusy(true);
                dataservice.login.getLoginInfo()
                    .then(function () {
                        vm.global.isBusy(false);
                        // Resolve if already logged in
                        if (self.user().isLoggedIn()) {
                            resolve();
                        } else {
                            // Resolve after successfull log in
                            var sub = self.user().isLoggedIn.subscribe(function (isLoggedIn) {
                                if (isLoggedIn === true) {
                                    resolve();
                                    sub.dispose();
                                }
                            });
                        }
                    })
                    .catch(function (e) {
                        vm.global.isBusy(false);
                        reject(e);
                    });
            });
        };

    // Logged in by default to prevent blinking login window
    user().isLoggedIn(true);

    return {
        user: user,
        hasPasswordFocus: hasPasswordFocus,
        loginError: loginError,
        showWarningOffline: showWarningOffline,

        init: init,
        login: login,
        logout: logout,
        toggleShowWarningOffline: toggleShowWarningOffline,
        logoutWithCheck: logoutWithCheck
    };

})();