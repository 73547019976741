var model = model || {};

var prop = prop || function (extend) { return ko.observable().extend(extend); }
var propArray = propArray || function (extend) { return ko.observableArray().extend(extend); }

model.UpdateInspectionRequest = function () {
    var self = this;
    
    self.inspection = prop({ type: model.Inspection });
    self.closeInspection = prop({ bool: true });
}

model.Inspection = function () {
    var self = this;

    self.id = prop({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.date = prop({ title: "Datum" });
    self.eventId = prop({ title: "Händelse ID", integer: true });
    self.coreObjectId = prop({ title: "Objekt ID", integer: true });
    self.memoId = prop({ title: "Memo ID", integer: true });
    self.isLso22 = prop({ title: "LSO", bool: true, defaultValue: false });
    self.isLbe = prop({ title: "LBE", bool: true, defaultValue: false });
    self.isLso24 = prop({ title: "LSO 2:4", bool: true, defaultValue: false });
    self.caseWorkerId = prop({ integer: true });
    self.caseWorker = prop({ title: "Handläggare", maxLength: 35 });

    // Related data
    self.checkLists = propArray({ type: model.InspectionChecklist });
    self.memo = prop({ title: "Memo", type: model.Memo, defaultValue: new model.Memo });

    // Only one of the case worker props can have a value
    self.caseWorkerId.subscribe(function (val) {
        if (!val) self.caseWorkerId(undefined);
        if (val) self.caseWorker(undefined);
    });
    self.caseWorker.subscribe(function (val) {
        if (val) self.caseWorkerId(undefined);
    });
}

model.InspectionChecklist = function () {
    var self = this;

    self.id = prop({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.name = prop({ title: "Område" });
    self.sortOrder = prop({ title: "SortOrder", integer: true });
    self.isActive = prop({ title: "Aktiv", bool: true });

    // Related data
    self.sections = propArray({ title: "Sektioner", type: model.InspectionSection });

    // Computed
    self.nameWithQuestionCount = ko.pureComputed(function () {
        var total = 0;
        ko.utils.arrayForEach(self.sections(), function (s) { total += s.questions().length; });
        return self.name() + " (" + total + ")";
    });
    self.nrQuestionsWithoutWarningAnswer = ko.pureComputed(function () {
        var total = 0;
        ko.utils.arrayForEach(self.sections(), function (s) { total += s.nrQuestionsWithoutWarningAnswer(); });
        return total;
    });
    self.nrQuestionsWithWarningAnswer = ko.pureComputed(function () {
        var total = 0;
        ko.utils.arrayForEach(self.sections(), function (s) { total += s.nrQuestionsWithWarningAnswer(); });
        return total;
    });
}
model.InspectionChecklist.prototype.toJSON = function () {
    var copy = ko.toJS(this);
    delete copy.nameWithQuestionCount;
    delete copy.nrQuestionsWithoutWarningAnswer;
    delete copy.nrQuestionsWithWarningAnswer;
    return copy;
};

model.InspectionSection = function () {
    var self = this;

    self.id = prop({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.section = prop({ title: "Kontrollpunkt" });

    // Related data
    self.questions = propArray({ title: "Frågor", type: model.InspectionQuestion });

    // Computed
    self.sectionWithQuestionCount = ko.pureComputed(function () {
        return self.section() + " (" + self.questions().length + ")";
    });
    self.nrQuestionsWithoutWarningAnswer = ko.pureComputed(function () {
        return ko.utils.arrayFilter(self.questions(), function (q) { return q.answers().length - q.nrAnswersWithWarning(); }).length;
    });
    self.nrQuestionsWithWarningAnswer = ko.pureComputed(function () {
        return ko.utils.arrayFilter(self.questions(), function (q) { return q.nrAnswersWithWarning(); }).length;
    });
}
model.InspectionSection.prototype.toJSON = function () {
    var copy = ko.toJS(this);
    delete copy.sectionWithQuestionCount;
    delete copy.nrQuestionsWithoutWarningAnswer;
    delete copy.nrQuestionsWithWarningAnswer;
    return copy;
};

model.InspectionQuestion = function () {
    var self = this;

    self.id = prop({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.questionText = prop({ title: "Fråga" });
    self.helpText = prop({ title: "Hjälptext" });

    // Related data
    self.alternatives = propArray({ type: model.InspectionAlternative });
    self.answers = propArray({ type: model.InspectionAnswer });

    // Computed
    self.nrAnswersWithoutWarning = ko.pureComputed(function () {
        return ko.utils.arrayFilter(self.answers(), function (a) { return !a.warning(); }).length;
    });
    self.nrAnswersWithWarning = ko.pureComputed(function () {
        return ko.utils.arrayFilter(self.answers(), function (a) { return a.warning(); }).length;
    });
}
model.InspectionQuestion.prototype.toJSON = function () {
    var copy = ko.toJS(this);
    delete copy.nrAnswersWithoutWarning;
    delete copy.nrAnswersWithWarning;
    return copy;
};

model.InspectionAlternative = function () {
    var self = this;

    self.id = prop({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.questionId = prop({ title: "Fråga ID", integer: true });
    self.answer = prop({ title: "Bedömning" });
    self.warning = prop({ title: "Anmärkning", bool: true });
    self.note = prop({ title: "Anteckning" });
}

model.InspectionAnswer = function () {
    var self = this;

    self.id = prop({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.inspectionId = prop({ title: "Inspektion ID", integer: true });
    self.questionId = prop({ title: "Fråga ID", integer: true });
    self.memoId = prop({ title: "Memo ID", integer: true });
    self.questionText = prop({ title: "Fråga" });
    self.answer = prop({ title: "Bedömning" });
    self.warning = prop({ title: "Anmärkning", bool: true });
    self.actionDeadline = prop({ title: "Åtgärdas senast" });
    self.warningCorrected = prop({ title: "Åtgärdat", bool: true });
    self.correctionDate = prop({ title: "Åtgärdat" });
    self.correctionMemoId = prop({ integer: true });

    // Related data
    self.memo = prop({ title: "Memo", type: model.Memo });
    self.memo(new self.memo.type);
    self.documents = propArray({ title: "Dokument", type: model.InspectionDocument });
}

model.InspectionHistoryQuestion = function () {
    var self = this;

    self.sectionId = prop({ integer: true });
    self.questionId = prop({ integer: true });
    self.section = prop({ title: "Kontrollpunkt" });
    self.questionText = prop({ title: "Fråga" });

    // Related data
    self.answers = propArray({ type: model.InspectionHistoryAnswer });

    // Computed
    self.nrWarningCorrectedAnswers = ko.pureComputed(function () {
        return ko.utils.arrayFilter(self.answers(), function (a) { return a.warningCorrected(); }).length;
    });
    self.nrOverdueAnswers = ko.pureComputed(function () {
        return ko.utils.arrayFilter(self.answers(), function (a) { return a.isOverdueActual(); }).length;
    });
}
model.InspectionHistoryQuestion.prototype.toJSON = function () {
    var copy = ko.toJS(this);
    delete copy.nrWarningCorrectedAnswers;
    delete copy.nrOverdueAnswers;
    return copy;
};

model.InspectionHistoryAnswer = function () { // Derives from model.InspectionAnswer
    var self = this;

    model.InspectionAnswer.call(self);

    self.isOverdue = prop({ bool: true });
    self.correctionMemo = prop({ title: "Anteckning för åtgärd" });
    self.inspectionDate = prop({ title: "Tillsynsdatum" });
    self.inspectionType = prop({ title: "Tillsynstyp" });
    self.isFromLatestInspection = prop({ bool: true });

    self.isOverdueActual = ko.pureComputed(function () {
        return self.actionDeadline() < utils.today() && !self.warningCorrected();
    });
}
model.InspectionHistoryAnswer.prototype = Object.create(model.InspectionAnswer.prototype);
model.InspectionHistoryAnswer.prototype.constructor = model.InspectionHistoryAnswer;
model.InspectionHistoryAnswer.prototype.toJSON = function () {
    var copy = ko.toJS(this);
    delete copy.isOverdueActual;
    return copy;
};

model.Memo = function () {
    var self = this;

    self.id = prop({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.code = prop({ title: "Typkod" });
    self.description = prop({ title: "Anteckning", defaultValue: "" });
    self.foreignKeyId = prop({ integer: true });

    // Computed
    self.shortDescription = ko.pureComputed(function () {
        var description = self.description();
        if (!description) return;
        description = description.substring(0, 70);
        return description.length < self.description().length ? description + "..." : description;
    });
}
model.Memo.prototype.toJSON = function () {
    var copy = ko.toJS(this);
    delete copy.shortDescription;
    return copy;
};

model.InspectionDocument = function () {
    var self = this;

    self.id = prop({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.path = prop({ title: "Sökväg" });
    self.description = prop({ title: "Beskrivning", maxLength: 255 });
    self.imageData = prop();

    // Computed
    self.hasImageData = ko.pureComputed(function () {
        return !!self.imageData();
    });
}
model.InspectionDocument.prototype.toJSON = function () {
    var copy = ko.toJS(this);
    delete copy.hasImageData;
    return copy;
};

model.InspectionContact = function () {
    var self = this;

    self.id = prop({ title: "ID", integer: true, required: true, defaultValue: 0 });
    self.contactId = prop({ integer: true, defaultValue: 0 });
    self.inspectionId = prop({ integer: true, defaultValue: 0 });
    self.name = prop({ title: "Namn" });
    self.phone = prop({ title: "Telefon" });
    self.organisation = prop({ title: "Organisation" });
    self.email = prop({ title: "E-post" });

    // Required if changed
    self.name.extend({ requiredIfChanged: { property: self.name } });
}
