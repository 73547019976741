var model = model || {};

model.SearchHit = function () {
    var self = this;

    self.objectId = ko.observable().extend({ title: "ID", integer: true });
    self.objectName = ko.observable().extend({ title: "Objekt" });
    self.estateName = ko.observable().extend({ title: "Fastighet" });
    self.address = ko.observable().extend({ title: "Adress" });
    self.caseWorker = ko.observable().extend({ title: "Handläggare" });
    self.distanceToDevice = ko.observable().extend({ title: "Avstånd", number: true });

    // Client use only
    self.isFromCache = ko.observable();
};
