var vm = vm || {};

vm.ObjectBase = function () {
    vm.Base.call(this);
    this.object = dataservice.object.data().object;
}

vm.ObjectBase.prototype = Object.create(vm.Base.prototype);
vm.ObjectBase.prototype.constructor = vm.ObjectBase;

vm.ObjectBase.prototype.submit = function () {
    vm.global.isBusy(true);
    return dataservice.object.updateObject()
        .then(function () {
            vm.global.addNotification("Objektet har sparats.", "success");
            vm.global.isBusy(false);
        })
        .catch(function (e) {
            vm.global.addNotification("Det gick inte att spara, vänligen försök igen.", "error");
            vm.global.isBusy(false);
            throw e;
        });
}

vm.ObjectBase.prototype.canNavigateAway = function () {
    var editing = this.editing();
    if (editing) {
        vm.global.addNotification("Du är i redigeringsläge och kan inte byta vy. Spara eller avbryt redigeringen och försök igen.", "error");
    }
    return !editing;
}

vm.ObjectBase.prototype.init = function (params) {
    return vm.Base.prototype.init.call(this, params)
        .catch(function () {
            vm.global.addNotification("Det gick inte att hämta objektet.", "error");
        });
}
