var vm = vm || {};

vm.ObjectForvaltning = function () {
    vm.ObjectBase.call(this);

    this.organisationRoles = dataservice.object.organisationRoles;

    this.selectedOrganisation = ko.observable();
    this.selectedContacts = ko.observableArray();
    this.selectedOrganisation.subscribe(function (organisation) {
        this.selectedContactPerson(null);
        if (organisation)
            this.selectedContacts(organisation.contacts());
        else
            this.selectedContacts([]);
    }, this);
    this.showConfirmRemoveOrganisation = ko.observable();
    this.selectedContactPerson = ko.observable();
    this.showConfirmRemoveContactPerson = ko.observable();
}

vm.ObjectForvaltning.prototype = Object.create(vm.ObjectBase.prototype);
vm.ObjectForvaltning.prototype.constructor = vm.ObjectForvaltning;

vm.ObjectForvaltning.prototype.addOrganisation = function () {
    var organisation = new (this.object().organisations.type);
    this.object().organisations.push(organisation);
    this.selectedOrganisation(organisation);
    this.selectedContacts([]);
    this.selectedContacts(organisation.contacts());
    this.validationErrors.showAllMessages();
}

vm.ObjectForvaltning.prototype.addContactPerson = function () {
    var contactPerson = new (this.selectedOrganisation().contacts.type);
    this.selectedOrganisation().contacts.push(contactPerson);
    this.selectedContacts([]);
    this.selectedContacts(this.selectedOrganisation().contacts());
    this.selectedContactPerson(contactPerson);
    this.validationErrors.showAllMessages();
}

vm.ObjectForvaltning.prototype.toggleShowConfirmRemoveOrganisation = function () {
    this.showConfirmRemoveOrganisation(!this.showConfirmRemoveOrganisation());
}

vm.ObjectForvaltning.prototype.toggleShowConfirmRemoveContactPerson = function () {
    this.showConfirmRemoveContactPerson(!this.showConfirmRemoveContactPerson());
}

vm.ObjectForvaltning.prototype.removeOrganisation = function () {
    this.object().organisations.remove(this.selectedOrganisation());
    this.selectedOrganisation(null);
    this.selectedContacts([]);
    this.selectedContactPerson(null);
}

vm.ObjectForvaltning.prototype.removeContactPerson = function () {
    this.selectedOrganisation().contacts.remove(this.selectedContactPerson());
    this.selectedContactPerson(null);
    this.selectedContacts([]);
    this.selectedContacts(this.selectedOrganisation().contacts());
}

vm.ObjectForvaltning.prototype.rollback = function () {
    vm.ObjectBase.prototype.rollback.call(this);
    if (this.selectedOrganisation() && this.selectedOrganisation().id() === 0)
        this.selectedOrganisation(null);
    if (this.selectedContactPerson() && this.selectedContactPerson().id() === 0)
        this.selectedContactPerson(null);
    this.selectedContacts([]);
    if (this.selectedOrganisation())
        this.selectedContacts(this.selectedOrganisation().contacts());
}

vm.ObjectForvaltning.prototype.init = function (params) {
    var self = this;
    return vm.ObjectBase.prototype.init.call(this, params)
        .then(function () {
            // Subscribe to object changes and clear selected so they are updated when refreshing from database
            if (!dataservice.object.data().object.forvaltningSubscription)
                dataservice.object.data().object.forvaltningSubscription = dataservice.object.data().object.subscribe(function () {
                self.selectedOrganisation(null);
                self.selectedContactPerson(null);
            });
        });
}

vm.objectForvaltning = new vm.ObjectForvaltning();
