var model = model || {};

model.CaseWorker = function () {
    var self = this;

    self.id = ko.observable();
    self.signature = ko.observable();
    self.firstName = ko.observable();
    self.lastName = ko.observable();
    self.isCurrentUser = ko.observable();

    self.fullName = ko.pureComputed(function () {
        var firstName = self.firstName();
        var lastName = self.lastName();
        return (firstName ? firstName + " " : "") + (lastName || "");
    });
};
