var vm = vm || {};

vm.sync = (function () {

    var
        step = ko.observable(),
        total = ko.observable(),
        isBusy = ko.observable().extend({ rateLimit: 100 }),
        syncResult = ko.observable(),
        showSyncDialog = ko.pureComputed(function () {
            if (offlineCache.hasUnsyncedChanges()) {
                step(0);
                total(0);
                syncResult(undefined);
            }
            return (vm.global.isOnline() && offlineCache.hasUnsyncedChanges()) || syncResult() !== undefined;
        }),
        sync = function () {
            isBusy(true);
            offlineCache.syncChanges(step, total)
                .then(function () {
                    syncResult(true);
                    isBusy(false);
                }, function () {
                    syncResult(false);
                    isBusy(false);
                });
        };

    return {
        step: step,
        total: total,
        isBusy: isBusy,
        syncResult: syncResult,
        showSyncDialog: showSyncDialog,
        sync: sync
    };

})();
