var vm = vm || {};

vm.Base = function () {
    this.ready = ko.observable();

    this.alwaysEditing = ko.observable(false);
    var editing = ko.observable();
    this.editing = ko.pureComputed({
        read: function () { return this.alwaysEditing() || editing(); },
        write: function (val) { editing(val); }
    }, this);

    this.currentId = dataservice.object.currentId;
    this.validationErrors = dataservice.object.validationErrors;
    this.isValid = dataservice.object.isValid;
    this.isDirty = dataservice.object.isDirty;
}

vm.Base.prototype.edit = function () {
    this.editing(true);
}

vm.Base.prototype.rollback = function () {
    this.editing(false);
    dataservice.object.rollback();
}

vm.Base.prototype.commit = function () {
    var self = this,
        result;
    var done = function () {
        self.editing(false);
        dataservice.object.commit();
    }
    if (typeof this.submit === "function") {
        result = this.submit();
    }
    result ? result.then(done) : done();
}

vm.Base.prototype.refresh = function () {
    var self = this;
    this.ready(false);
    vm.global.isBusy(true);
    return dataservice.object.refresh().then(function () {
        self.ready(true);
        vm.global.isBusy(false);
    }, function () {
        vm.global.isBusy(false);
    });
}

vm.Base.prototype.init = function (params) {
    var self = this;
    var beforeInit = function () {
        self.ready(false);
        vm.global.isBusy(true);
    };

    return dataservice.object.init(params.id, beforeInit)
        .then(function (result) {
            return tekis.components.init()
                .then(function () {
                    self.ready(true);
                    vm.global.isBusy(false);
                    return result;
                });
        })
        .catch(function (e) {
            vm.global.isBusy(false);
            throw e;
        });
}

