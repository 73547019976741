var model = model || {};

model.SafetyReports = function () {
	var self = this;

	// Skriftlig redogörelse
	self.latestReport = ko.observable().extend({ title: "Senaste redogörelse" });
	self.plannedReport = ko.observable().extend({ title: "Planerad redogörelse" });
	self.safetyReportNumber = ko.observable().extend({ title: "Redogörelsenummer" });
    self.diarieNumber = ko.observable().extend({ title: "Diarienummer" });

	// Allmänt
	self.estateName = ko.observable().extend({ title: "Fastighetsbeteckning" });
	self.owner = ko.observable().extend({ title: "Fastighetsägare" });
	self.visitingAddress = ko.observable().extend({ title: "Besöksadress" });
	self.postalAddress = ko.observable().extend({ title: "Utdelningsadress" });
	self.postalNumber = ko.observable().extend({ title: "Postnummer" });
	self.city = ko.observable().extend({ title: "Ort" });
	self.contact = ko.observable().extend({ title: "Kontaktperson" });
	self.phone = ko.observable().extend({ title: "Telefon" });
	self.createdBy = ko.observable().extend({ title: "Upprättad av" });
	self.createDate = ko.observable().extend({ title: "Upprättad datum" });
	self.ssn = ko.observable().extend({ title: "Organisationsnr" });
    // Byggnad
	self.yearBuilt = ko.observable().extend({ title: "Byggnadsår" });
	self.yearReconstructed = ko.observable().extend({ title: "Ombyggnadsår" });
	self.floors = ko.observable().extend({ title: "Antal våningar" });
	self.basementFloors = ko.observable().extend({ title: "Antal källarplan" });
	self.size = ko.observable().extend({ title: "Total yta" });
	self.businessAreas = ko.observableArray().extend({ title: "Verksamheter", type: String });

    // Verksamhetsredogörelser
	self.businesses = ko.observableArray().extend({ type: model.SafetyReportBusiness });

	// Anteckning
	self.note = ko.observable().extend({ title: "Anteckning" });
}

model.SafetyReportBusiness = function () {
	var self = this;

    // General
	self.refNr = ko.observable().extend({ title: "Redogörelsenummer" });
	self.estateName = ko.observable().extend({ title: "Fastighetsbeteckning" });
	self.organisationName = ko.observable().extend({ title: "Namn" });
	self.ssn = ko.observable().extend({ title: "Organisationsnr" });
	self.visitingAddress = ko.observable().extend({ title: "Besöksadress" });
	self.postalAddress = ko.observable().extend({ title: "Utdelningsadress" });
	self.postalNumber = ko.observable().extend({ title: "Postnummer" });
	self.city = ko.observable().extend({ title: "Ort" });
	self.contact = ko.observable().extend({ title: "Kontaktperson" });
	self.phone = ko.observable().extend({ title: "Telefon" });
    // Business
	self.floors = ko.observable().extend({ title: "Verksamhet på våning" });
	self.basementFloors = ko.observable().extend({ title: "Verksamhet på källarplan" });
	self.size = ko.observable().extend({ title: "Verksamhetens yta" });
	self.businessHours = ko.observable().extend({ title: "Verksamhetstider" });
	self.businessAreas = ko.observableArray().extend({ title: "Verksamheter", type: String });
	self.fireProtectionResponsibility = ko.observable().extend({ title: "Är ansvaret för brandskyddet klarlagt" });
	self.fireProtectionResponsibilityNote = ko.observable().extend({ title: "Brandskyddsbeskrivning" });
	self.maxEmployees = ko.observable().extend({ title: "Max anställda" });
	self.minEmployees = ko.observable().extend({ title: "Min anställda" });
	self.maxPersons = ko.observable().extend({ title: "Max personer totalt" });
	self.inflammableMaterials = ko.observable().extend({ title: "Hanteras brandfarlig vara" });
	self.inflammableMaterialsPermission = ko.observable().extend({ title: "Om ja, hanteras sådana mängder att tillstånd krävs" });
    // Fire protection building
	self.fireProtectionDrawings = ko.observable().extend({ title: "Finns aktuella brandskyddsritningar" });
	self.fireProtectionInstallations = ko.observableArray().extend({ title: "Fasta brandskyddsinstallationer", type: String });
	self.fireProtectionInstallationsNote = ko.observable().extend({ title: "Brandskyddsinstallation (fritext)" });
	self.fireProtectionOther = ko.observableArray().extend({ title: "Övriga brandskyddsinstallationer/redskap", type: String });
	self.fireProtectionOtherNote = ko.observable().extend({ title: "Övrig brandskyddsinstallation (fritext)" });
    // Organisational fire protection 
	self.responsibilityMaintenanceOwnerBusiness = ko.observable().extend({ title: "Finns ansvarsfördelning underhåll/kontroll brandskyddet mellan ägare och verksamheten" });
	self.maintenanceRoutines = ko.observable().extend({ title: "Finns rutiner för underhåll/kontroll brandskyddet" });
	self.maintenanceDocumented = ko.observable().extend({ title: "Om ja, dokumenteras underhållet och kontrollerna" });
	self.employeesMaintenanceCompetence = ko.observable().extend({ title: "Har personalen kompetens för underhåll brandskyddet" });
	self.fixDefectsRoutines = ko.observable().extend({ title: "Finns rutiner för att åtgärda brister" });
	self.incidentReportingRoutines = ko.observable().extend({ title: "Finns dokumenterade rutiner för tillbudsrapportering/uppföljning av brandtillbud" });
	self.evacuationPlanned = ko.observable().extend({ title: "Finns planering för utrymmning av lokalerna vid brand" });
	self.employeesEvacuationInformed = ko.observable().extend({ title: "Är personalen informerad om utrymmningsplaneringen" });
	self.evacuationPractice = ko.observable().extend({ title: "Genomförs utrymmningsövningar" });
	self.evacuationPracticeOtherBusinesses = ko.observable().extend({ title: "Genomförs utrymmningsövningar med andra verksamheter" });
	self.responsibilityFireOwnerBusiness = ko.observable().extend({ title: "Finns ansvarsfördelning mellan ägare och verksamheten vid brand" });
	self.fireEmergencyOrganised = ko.observable().extend({ title: "Finns organisation för nödläge vid brand" });
	self.fireEmergencyDocumented = ko.observable().extend({ title: "Om ja, är den dokumenterad" });
	self.employeesFireInstructions = ko.observable().extend({ title: "Vet personalen sina uppgifter vid brand" });
	self.employeesFireInstructionsCompetence = ko.observable().extend({ title: "Har personalen kompetens att utföra uppgifterna" });
	self.employeesFireInstructionsCompetenceTheory = ko.observable().extend({ title: "Upprätthålls kompetensen genom teoretisk utbildning" });
	self.employeesFireInstructionsCompetencePractical = ko.observable().extend({ title: "Upprätthålls kompetensen genom praktisk övning" });
	self.businessFireDependent = ko.observable().extend({ title: "Är verksamheten beroende av att samverka med andra verksamheter vid brand" });
	self.businessFireDependentPlanned = ko.observable().extend({ title: "Om ja, är denna samverkan planerad" });
    // Other note
	self.otherNote = ko.observable().extend({ title: "Övriga kommentarer eller information" });
}