var vm = vm || {};

vm.ObjectRedogorelser = function () {
    vm.Base.call(this);
    this.safetyReports = dataservice.object.safetyReports;
}

vm.ObjectRedogorelser.prototype = Object.create(vm.Base.prototype);
vm.ObjectRedogorelser.prototype.constructor = vm.ObjectRedogorelser;

vm.ObjectRedogorelser.prototype.init = function (params) {
    vm.Base.prototype.init.call(this, params)
        .catch(function () {
            vm.global.addNotification("Det gick inte att hämta redogörelserna för detta object.", "error");
        });
}

vm.objectRedogorelser = new vm.ObjectRedogorelser();
