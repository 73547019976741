var application = (function () {

    var
        initialize = function () {
            utils.log("Run");
            utils.logDebug("Cookies: " + document.cookie);

            // Stuff for mobile devices
            utils.removeHoverEffects();
            utils.noChosenForAndroid();

            // Set locale to swedish for components
            ko.validation.locale("sv-SE");
            moment.locale("sv");

            // Tekis Components
            tekis.components.parentVmType(vm.Base);

            // Init stuff
            navigation.init();
            server.init();
            offlineCache.init();

            // Bind view model to start the ui
            ko.applyBindings(vm.global);

            // Init login and start app
            vm.login.init().then(function () { navigation.run(); });

            // Remove all event listeners for cache so they are not called if another instance or app does things with cache
            window.applicationCache.removeEventListener("updateready", updateReady, false);
            window.applicationCache.removeEventListener("noupdate", initialize, false);
            window.applicationCache.removeEventListener("cached", initialize, false);
            window.applicationCache.removeEventListener("error", initialize, false);
        },

        updateReady = function () {
            window.location.reload.call(window.location);
        },

        run = function () {
            //window.addEventListener("load", function () {
                // If new application is available in cache, reload the page so it is used
                window.applicationCache.addEventListener("updateready", updateReady, false);
                // No update in cache, start application
                window.applicationCache.addEventListener("noupdate", initialize, false);
                window.applicationCache.addEventListener("cached", initialize, false);
                // Offline, start application
                window.applicationCache.addEventListener("error", initialize, false);
            //}, false);
        };

    return {
        run: run
    };

})();

$(application.run);
