var dataapi = dataapi || {};

dataapi.search = function () {
    var currentSearchRequest,
        usingText = function (text, selectedCaseWorkers) {
            var data = { search: text };
            data.caseWorkers = selectedCaseWorkers.join(",") || undefined;

            currentSearchRequest = utils.query("api/objects", {
                data: data
            });
            return currentSearchRequest;
        },
        usingPosition = function (text, selectedCaseWorkers) {
            return utils.geolocation.get()
                .then(function (coordinates) {
                    var data = { latitude: coordinates.latitude, longitude: coordinates.longitude, search: text || undefined };
                    data.caseWorkers = selectedCaseWorkers.join(",") || undefined;
                    currentSearchRequest = utils.query("api/objects", {
                        data: data
                    });
                    return currentSearchRequest;
                });
        },
        abort = function () {
            currentSearchRequest && currentSearchRequest.abort && currentSearchRequest.abort();
            currentSearchRequest = null;
        },
        getCaseWorkers = function () {
            return utils.query("api/caseworkers");
        };

    return {
        usingText: usingText,
        usingPosition: usingPosition,
        abort: abort,
        getCaseWorkers: getCaseWorkers
    };
}();
