var vm = vm || {};

vm.DocumentArchive = function () {
    vm.Base.call(this);
    this.documentArchive = dataservice.object.documentArchive;
}

vm.DocumentArchive.prototype = Object.create(vm.Base.prototype);
vm.DocumentArchive.prototype.constructor = vm.DocumentArchive;

vm.DocumentArchive.prototype.openDocument = function (document) {
    if (!vm.global.isOnline()) return false; // Cannot open anything when offline

    // type == link or unknown types take uri as location to open
    var location = document.uri(),
        token = sessionStorage.getItem("authorization_token");
    if (document.type() === "file")
        location = "api/archive/" + document.fileId() + (token ? "?token=" + token : "");

    if (document.type() === "file" && utils.hasFileImageExtension(document.uri()))
        // image files are shown in viewer
        vm.imageViewer.show({ url: location, description: document.name() || document.uri() });
    else
        window.open(location, "_blank");
}

vm.DocumentArchive.prototype.toggleInfo = function (element) {
    if (this.metaData().length) {
        $(element).parent().next().toggle();
    }
}

vm.DocumentArchive.prototype.init = function (params) {    
    vm.Base.prototype.init.call(this, params)
        .catch(function () {
            vm.global.addNotification("Det gick inte att hämta dokumentbanken.", "error");
        });
}

vm.documentArchive = new vm.DocumentArchive();
